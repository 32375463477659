import React, { useEffect, useCallback } from "react";

import { withState } from "../../../services/state";
import {
  verifyAccount as apiVerifyAccount,
  getProfile as apiGetProfile,
} from "../../../services/api";

import PageWrapper from "../../components/pageWrapper/PageWrapper";
import AuthenticationFlow from "../../components/authenticationFlow/AuthenticationFlow";

import VerifyAccountForm from "../../forms/verifyAccountForm/VerifyAccountForm";

const VerifyAccountPage = ({ history, match, context, dispatch }) => {
  const [currentStep, setCurrentStep] = React.useState(0);
  const [verifyAccountMessage, setVerifyAccountMessage] = React.useState("");
  const [verifyAccountError, setVerifyAccountError] = React.useState("");

  const verifyAccount = useCallback(
    async (payload) => {
      try {
        const data = await apiVerifyAccount({
          code: match.params.code,
          ...payload,
        });

        console.log(data, data.errors);
        if (data.success) {
          setVerifyAccountMessage(
            "Your account has been verified. You can setup your payment method by clicking pay on an event and following the link."
          );
          if (context.profile.id) {
            const profileData = await apiGetProfile();
            dispatch({
              type: "storeProfile",
              profile: profileData,
            });
          }
        } else if (Array.isArray(data.errors)) {
          setVerifyAccountError(data.errors[0].reason);
        }
      } catch (e) {
        console.log(e);
        setVerifyAccountError(e.message);
      }
    },
    [match.params.code, dispatch, context.profile.id]
  );

  useEffect(() => {
    if (context.loaded) {
      if (context.profile.isEmailActivated) {
        setVerifyAccountMessage(
          "Your account has been verified. You can setup your payment method by clicking pay on an event and following the link."
        );
      } else if (!context.profile.id || !context.profile.isEmailActivated) {
        verifyAccount();
      }
    }
  }, [
    verifyAccount,
    context.profile.id,
    context.profile.isEmailActivated,
    context.loaded,
  ]);

  return (
    <PageWrapper history={history} showHeader={true}>
      <AuthenticationFlow
        currentStep={currentStep}
        onGoBack={() => setCurrentStep(currentStep - 1)}
        steps={[
          <VerifyAccountForm
            error={verifyAccountError}
            message={verifyAccountMessage}
          />,
        ]}
      />
    </PageWrapper>
  );
};

export default withState(VerifyAccountPage);
