import React from "react";
import { withStyles } from "@material-ui/core/styles";

import Logo from "./Logo.png";

const styles = (theme) => ({
  authenticationFlowWrapper: {
    height: "100%",
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  authenticationFlow: {
    backgroundColor: "#fff",
    borderRadius: "3px",
    width: "100%",
    maxWidth: 1200,
    maxHeight: 800,
    height: "100%",
    overflow: "scroll",
    display: "flex",
    boxShadow: "0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23)",
    [theme.breakpoints.down("sm")]: {
      display: "block",
    },
  },
  authenticationFlowSidebar: {
    backgroundColor: "#393A39",
    color: "#fff",
    width: 150,
    position: "relative",
    padding: 10,
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  authenticationFlowLogo: {
    width: "100%",
    maxWidth: 100,
  },
  currentStep: {
    width: "calc(100% - 150px)",
    padding: 40,
    overflow: "scroll",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  stepNumber: {
    color: "#9C9C9C",
    marginBottom: 20,
  },
  backButton: {
    color: "#9C9C9C",
    position: "absolute",
    bottom: 20,
    width: "100%",
    textAlign: "center",
    cursor: "pointer",
  },
});

function renderCurrentStep(classes, currentStep) {
  return currentStep;
}

function AuthenticationFlow({ classes, style, currentStep, steps, onGoBack, onLogout }) {
  return (
    <div className={classes.authenticationFlowWrapper}>
      <div className={classes.authenticationFlow} style={style || {}}>
        <div className={classes.authenticationFlowSidebar}>
          <img className={classes.authenticationFlowLogo} alt="Slate logo" src={Logo} />
          {currentStep === 0 && onLogout && (
            <div onClick={() => onLogout()} className={classes.backButton}>
              Logout
            </div>
          )}
          {currentStep !== 0 && (
            <div onClick={() => onGoBack()} className={classes.backButton}>
              Back
            </div>
          )}
        </div>
        <div className={classes.currentStep}>
          {steps.length > 1 && <div className={classes.stepNumber}>Step {currentStep + 1}</div>}
          {renderCurrentStep(classes, steps[currentStep])}
        </div>
      </div>
    </div>
  );
}

export default withStyles(styles)(AuthenticationFlow);
