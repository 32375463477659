import React from "react";

import { withState } from "../../../services/state";
import { acceptInvite as apiAcceptInvite } from "../../../services/api";

import PageWrapper from "../../components/pageWrapper/PageWrapper";
import AuthenticationFlow from "../../components/authenticationFlow/AuthenticationFlow";

import AcceptInviteForm from "../../forms/acceptInviteForm/AcceptInviteForm";

const isInvalidEmail = (email) => !email;
const isInvalidPassword = (password) => !password || password.length < 6;

const AcceptInvitePage = ({ history, match, dispatch }) => {
  const [currentStep, setCurrentStep] = React.useState(0);
  const [acceptingInvite, setAcceptingInvite] = React.useState(false);
  const [acceptInviteError, setAcceptInviteError] = React.useState("");
  const [inviteAccepted, setInviteAccepted] = React.useState(false);

  const acceptInvite = async (payload) => {
    try {
      setAcceptInviteError(null);

      if (isInvalidEmail(payload.emailAddress)) {
        setAcceptInviteError("Please enter a valid email address");
        return false;
      }

      if (isInvalidPassword(payload.password)) {
        setAcceptInviteError("Please enter a valid password");
        return false;
      }

      if (!payload.agreeToTerms) {
        setAcceptInviteError("Please agree to the terms and conditions");
        return false;
      }

      setAcceptingInvite(true);
      const acceptInviteData = await apiAcceptInvite({ code: match.params.code, ...payload });
      console.log(acceptInviteData);
      if (acceptInviteData.success) {
        setInviteAccepted(true);
      } else if (acceptInviteData.errors) {
        setAcceptInviteError(acceptInviteData.errors[0].reason);
      } else {
        setAcceptInviteError("An error occurred trying to request password reset");
      }
      setAcceptingInvite(false);
    } catch (e) {
      console.log(e);
      setAcceptingInvite(false);
      setAcceptInviteError(e.message);
    }
  };

  console.log(acceptInviteError);

  return (
    <PageWrapper history={history} showHeader={true}>
      <AuthenticationFlow
        currentStep={currentStep}
        onGoBack={() => setCurrentStep(currentStep - 1)}
        steps={[
          <AcceptInviteForm
            onSubmit={(payload) => {
              acceptInvite(payload);
            }}
            submitting={acceptingInvite}
            error={acceptInviteError}
            submitText="Save password"
            inviteAccepted={inviteAccepted}
          />,
        ]}
      />
    </PageWrapper>
  );
};

export default withState(AcceptInvitePage);
