import React from "react";
import { withStyles } from "@material-ui/core/styles";

import FormHeader from "../../components/formHeader/FormHeader";
import SingleLineTextInput from "../../components/singleLineTextInput/SingleLineTextInput";
import DatePickerInput from "../../components/datePickerInput/DatePickerInput";

// const isInvalidName = name => !name;

const styles = (theme) => ({
  section: {
    width: "100%",
    backgroundColor: "#fff",
  },
});

function EditEventTypeForm({
  classes,
  payload,
  storePayload,
  onSubmit,
  errorMessage,
  submitting,
}) {
  // const [submitAttempted, setSubmitAttempted] = React.useState(false);

  return (
    <div className={classes.section}>
      <div className={classes.setupInformation}>
        <FormHeader title="Edit event type" />
      </div>
      <SingleLineTextInput
        label="EVENT NAME"
        value={payload.name}
        onChange={(value) => storePayload({ ...payload, name: value })}
        // showInvalidInput={submitAttempted && isInvalidName(payload.name)}
      />
      <DatePickerInput
        label="EVENT DATE"
        value={payload.eventDate}
        onChange={(value) => storePayload({ ...payload, eventDate: value })}
        // showInvalidInput={submitAttempted && isInvalidName(payload.eventDate)}
      />
    </div>
  );
}

export default withStyles(styles)(EditEventTypeForm);
