import React from "react";
import { withStyles } from "@material-ui/core/styles";

import SingleLineTextInput from "../../components/singleLineTextInput/SingleLineTextInput";

// const isInvalidName = (name) => !name;

const styles = (theme) => ({
  section: {
    width: "100%",
    backgroundColor: "#fff",
  },
});

function EditEventFeeForm({ classes, payload, storePayload, onSubmit, errorMessage, submitting }) {
  // const [submitAttempted, setSubmitAttempted] = React.useState(false);

  return (
    <div className={classes.section}>
      <SingleLineTextInput
        label="EVENT FEE"
        value={payload.currentFee}
        onChange={(value) => storePayload({ ...payload, currentFee: value })}
        // showInvalidInput={submitAttempted && isInvalidName(payload.name)}
        startAdornment="£"
      />
    </div>
  );
}

export default withStyles(styles)(EditEventFeeForm);
