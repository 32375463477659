import React, { useEffect, useCallback } from "react";
import { Switch, Route, Router, Redirect } from "react-router";
import { MuiThemeProvider, createMuiTheme } from "@material-ui/core/styles";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";

import {
  getProfile as apiGetProfile,
  refreshToken as apiRefreshToken,
  loginToClub as apiLoginToClub,
  getOrganizationData as apiGetOrganizationData,
} from "./services/api";

import LoginPage from "./ui/pages/loginPage/LoginPage";
import RegisterPage from "./ui/pages/registerPage/RegisterPage";
import ForgotPasswordPage from "./ui/pages/forgotPasswordPage/ForgotPasswordPage";
import ResetPasswordPage from "./ui/pages/resetPasswordPage/ResetPasswordPage";
import AcceptInvitePage from "./ui/pages/acceptInvitePage/AcceptInvitePage";
import VerifyAccountPage from "./ui/pages/verifyAccountPage/VerifyAccountPage";
import SplashPage from "./ui/pages/splashPage/SplashPage";
import NoClubsPage from "./ui/pages/noClubsPage/NoClubsPage";
// import HomePage from "./ui/pages/homePage/HomePage";
import EventsPage from "./ui/pages/eventsPage/EventsPage";
import EventPage from "./ui/pages/eventPage/EventPage";
import CreateEventPage from "./ui/pages/createEventPage/CreateEventPage";
import EditEventPage from "./ui/pages/editEventPage/EditEventPage";
import ArchivePage from "./ui/pages/archivePage/ArchivePage";
import ArchivedEventPage from "./ui/pages/archivedEventPage/ArchivedEventPage";
import OutstandingFeesPage from "./ui/pages/outstandingFeesPage/OutstandingFeesPage.js";
import CompleteMandateSetupPage from "./ui/pages/completeMandateSetupPage/CompleteMandateSetupPage";

import AdminLoginPage from "./ui/pages/adminLoginPage/AdminLoginPage";
import AdminSelectUserPage from "./ui/pages/adminSelectUserPage/AdminSelectUserPage";

import { withState } from "./services/state";
import history from "./services/history";

const jwtDecode = require("jwt-decode");

const theme = createMuiTheme({
  palette: {
    type: "light",
    primary: {
      main: "#ababab",
      contrastText: "#fff",
    },
    secondary: {
      main: "#14a99d",
      contrastText: "#fff",
    },
  },
  overrides: {
    MuiButton: {
      containedPrimary: {
        color: "white",
      },
      containedSecondary: {
        color: "white",
      },
    },
  },
});

const App = ({ dispatch, context }) => {
  const { loggedIn, loggedInAsAdmin, loaded, currentClub } = context;

  const Loading = ({ children }) =>
    !loaded ? <Switch>{children}</Switch> : null;
  const Unauthorized = ({ children }) =>
    loaded && !loggedIn && !loggedInAsAdmin ? (
      <Switch>{children}</Switch>
    ) : null;
  const AuthorizedWithoutClub = ({ children }) =>
    loaded && loggedIn && currentClub && !currentClub.id ? (
      <Switch>{children}</Switch>
    ) : null;
  const AuthorizedWithClub = ({ children }) =>
    loaded && loggedIn && currentClub && currentClub.id ? (
      <Switch>{children}</Switch>
    ) : null;
  const AuthorizedAdmin = ({ children }) =>
    loaded && loggedInAsAdmin && !loggedIn ? <Switch>{children}</Switch> : null;

  const init = useCallback(async ({ dispatch, context }) => {
    const { loaded } = context;
    if (loaded) {
      return false;
    }

    let organization = window.location.hostname.split(".")[0];
    let organizationSlug = null;

    if (organization === "app" || organization === "localhost") {
      organizationSlug = "slate";
    } else if (organization) {
      organizationSlug = organization;
    } else {
      organizationSlug = "slate";
    }

    let siteLoadedPayload = {
      type: "siteLoaded",
      loaded: true,
    };

    let organizationData = null;

    try {
      organizationData = await apiGetOrganizationData(organizationSlug);

      const existingTokensStr = localStorage.getItem(
        "slate-player-web-app-tokens"
      );

      if (existingTokensStr) {
        let existingTokens = null;
        try {
          existingTokens = JSON.parse(existingTokensStr);
          const data = await apiRefreshToken(existingTokens.refreshToken);

          if (data.accessToken) {
            siteLoadedPayload.loggedIn = true;

            dispatch({
              type: "storeToken",
              tokens: {
                accessToken: data.accessToken,
                refreshToken: data.refreshToken,
              },
            });

            // Decode the tokens to see if there is a clubId and playerId
            let decodedAccessToken = jwtDecode(data.accessToken);

            const getProfileData = await apiGetProfile();

            siteLoadedPayload.profile = getProfileData;

            if (decodedAccessToken.playerId) {
              siteLoadedPayload.currentPlayer = getProfileData.players.find(
                (player) => player.id === decodedAccessToken.playerId
              );
            } else {
              let eligiblePlayerMemberships = getProfileData.players.filter(
                (player) =>
                  player.memberships &&
                  player.memberships.filter(
                    (_) =>
                      _.enabled && _.organizationId === organizationData.guid
                  ).length > 0
              );
              siteLoadedPayload.currentPlayer = eligiblePlayerMemberships[0];
            }

            if (decodedAccessToken.clubId && siteLoadedPayload.currentPlayer) {
              siteLoadedPayload.currentClub = siteLoadedPayload.currentPlayer.memberships
                .filter(
                  (_) => _.enabled && _.organizationId === organizationData.guid
                )
                .find(
                  (membership) =>
                    membership.clubId === decodedAccessToken.clubId
                );
            } else if (siteLoadedPayload.currentPlayer) {
              siteLoadedPayload.currentClub = siteLoadedPayload.currentPlayer.memberships.filter(
                (_) => _.enabled && _.organizationId === organizationData.guid
              )[0];

              if (siteLoadedPayload.currentClub) {
                const loginToClubData = await apiLoginToClub(
                  siteLoadedPayload.currentClub.clubId,
                  siteLoadedPayload.currentPlayer.id
                );

                dispatch({
                  type: "storeToken",
                  tokens: {
                    accessToken: loginToClubData.accessToken,
                    refreshToken: loginToClubData.refreshToken,
                  },
                });
              }
            } else {
              history.push("/no-clubs");
            }
          }
        } catch (e) {
          console.log(e);
        }
      }
    } catch (e) {
      console.log(e);
    }

    siteLoadedPayload.organizationData = organizationData;

    dispatch(siteLoadedPayload);
  }, []);

  useEffect(() => {
    init({ dispatch, context });
  }, [context, dispatch, init]);

  return (
    <Router history={history}>
      <MuiThemeProvider theme={theme}>
        <MuiPickersUtilsProvider utils={MomentUtils}>
          <div className="App">
            <Loading>
              <Route path="*" component={SplashPage} />
            </Loading>

            <Unauthorized>
              <Route
                exact
                path="/godebit/mandate_thanks"
                component={CompleteMandateSetupPage}
              />
              <Route exact path="/login" component={LoginPage} />
              <Route exact path="/admin" component={AdminLoginPage} />
              <Route exact path="/register" component={RegisterPage} />
              <Route
                exact
                path="/forgot-password"
                component={ForgotPasswordPage}
              />
              <Route
                exact
                path="/password-reset/:code"
                component={ResetPasswordPage}
              />
              <Route
                exact
                path="/accept-invite/:code"
                component={AcceptInvitePage}
              />
              <Route
                exact
                path="/verify-account/:code"
                component={VerifyAccountPage}
              />
              <Redirect from="*" to="/login" />
            </Unauthorized>

            <AuthorizedWithoutClub>
              <Route exact path="/" component={SplashPage} />
              <Route exact path="/no-clubs" component={NoClubsPage} />
              <Route
                exact
                path="/verify-account/:code"
                component={VerifyAccountPage}
              />
              <Redirect from="*" to="/" />
            </AuthorizedWithoutClub>

            <AuthorizedWithClub>
              <Route
                exact
                path="/godebit/mandate_thanks"
                component={CompleteMandateSetupPage}
              />
              <Route
                exact
                path="/verify-account/:code"
                component={VerifyAccountPage}
              />
              <Route exact path="/events/new" component={CreateEventPage} />
              <Route
                exact
                path="/events/:eventId/edit"
                component={EditEventPage}
              />
              <Route exact path="/events/:eventId" component={EventPage} />
              <Route exact path="/events" component={EventsPage} />
              <Route exact path="/archive" component={ArchivePage} />
              <Route
                exact
                path="/archive/:eventId"
                component={ArchivedEventPage}
              />
              <Route
                exact
                path="/outstanding-fees"
                component={OutstandingFeesPage}
              />
              {/* <Route exact path="/" component={HomePage} /> */}
              <Redirect from="*" to="/events" />
            </AuthorizedWithClub>

            <AuthorizedAdmin>
              <Route
                exact
                path="/select-player"
                component={AdminSelectUserPage}
              />
              <Redirect from="*" to="/select-player" />
            </AuthorizedAdmin>
          </div>
        </MuiPickersUtilsProvider>
      </MuiThemeProvider>
    </Router>
  );
};

export default withState(App);
