import React, { useEffect, useCallback } from "react";
import { withStyles } from "@material-ui/core/styles";
import moment from "moment";

import { withState } from "../../../services/state";

import {
  getMembers as apiGetMembers,
  getEvent as apiGetEvent,
  addPlayerToEvent as apiAddPlayerToEvent,
  removePlayerFromEvent as apiRemovePlayerFromEvent,
  updatePlayerEventFee as apiUpdatePlayerEventFee,
  getPlayerClubPaymentStatus as apiGetPlayerClubPaymentStatus,
  submitPayment as apiSubmitPayment,
} from "../../../services/api";

import PageWrapper from "../../components/pageWrapper/PageWrapper";
import DialogModal from "../../components/dialogModal/DialogModal";
import Section from "../../components/section/Section";
import Label from "../../components/label/Label";
import Button from "../../components/button/Button";
import SingleLineTextInput from "../../components/singleLineTextInput/SingleLineTextInput";

import EventPlayersTable from "../../tables/eventPlayersTable/EventPlayersTable";
import ToggleEventPlayersForm from "../../forms/toggleEventPlayersForm/ToggleEventPlayersForm";
import EditEventFeeForm from "../../forms/editEventFeeForm/EditEventFeeForm";
import PayForEventForm from "../../forms/payForEventForm/PayForEventForm";

const styles = (theme) => ({
  root: {
    width: "100%",
  },
  detail: {
    marginBottom: 20,
  },
  choosePlayerButtons: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    marginBottom: 10,
    "& > div": {
      marginBottom: "0 5px",
    },
    [theme.breakpoints.down("sm")]: {
      display: "block",
      "& > div": {
        marginBottom: "10px",
      },
    },
  },
});

function EventPage({ history, match, classes, dispatch, context }) {
  const [showAddPlayer, toggleShowAddPlayer] = React.useState(false);
  const [searchTerm, setSearchTerm] = React.useState("");
  const [gettingMembers, setGettingMembers] = React.useState(true);
  const [members, setMembers] = React.useState([]);
  const [event, setEvent] = React.useState(true);
  // const [addingPlayerError, setAddPlayerError] = React.useState(true);
  const [addingPlayer, setAddingPlayer] = React.useState(true);
  // const [deletePlayerError, setDeletePlayerError] = React.useState(true);
  const [deletingPlayer, setDeletingPlayer] = React.useState(true);
  const [editEventFee, setEditEventFee] = React.useState(null);
  const [payEventFee, setPayEventFee] = React.useState(null);
  const [redirectFlowUrl, setRedirectFlowUrl] = React.useState(null);
  // const [gettingPaymentStatus, setGettingPaymentStatus] = React.useState(null);
  // const [gettingPaymentStatusError, setGettingPaymentStatusError] = React.useState(null);
  const [submittingPayment, setSubmittingPayment] = React.useState(false);
  const [submitPaymentError, setSubmitPaymentError] = React.useState(null);
  const [payForEventPassword, setPayForEventPassword] = React.useState("");

  const getMembers = useCallback(async () => {
    try {
      if (!context.currentClub.clubId) {
        return false;
      }

      setGettingMembers(true);
      const data = await apiGetMembers(
        context.currentClub.clubId,
        context.currentPlayer.id
      );
      if (data && !data.reason) {
        setMembers(data);
      }
      setGettingMembers(false);
    } catch (e) {
      console.log(e);
      setGettingMembers(false);
    }
  }, [context.currentClub.clubId, context.currentPlayer.id]);

  const getEvent = useCallback(async () => {
    try {
      const data = await apiGetEvent(match.params.eventId);
      if (data && !data.reason) {
        setEvent(data);
      }
    } catch (e) {
      console.log(e);
    }
  }, [match.params.eventId]);

  const addPlayerToEvent = async (member) => {
    try {
      setAddingPlayer(member.playerId);
      await apiAddPlayerToEvent(match.params.eventId, member.playerId);
      setAddingPlayer(false);
      getEvent();
    } catch (e) {
      setAddingPlayer(false);
      // setAddPlayerError(e.message);
    }
  };

  const removePlayerFromEvent = async (eventPlayer) => {
    try {
      setDeletingPlayer(eventPlayer.player.id);
      await apiRemovePlayerFromEvent(
        match.params.eventId,
        eventPlayer.player.id
      );
      getEvent();
      setDeletingPlayer(false);
    } catch (e) {
      setDeletingPlayer(false);
      // setDeletePlayerError(e.message);
    }
  };
  const updatePlayerEventFee = async () => {
    try {
      await apiUpdatePlayerEventFee(
        match.params.eventId,
        editEventFee.player.id,
        editEventFee.currentFee
      );
      setEditEventFee(null);
      getEvent();
    } catch (e) {
      // setDeletePlayerError(e.message);
    }
  };

  const isPlayerSelected = (member) => {
    return event.players.find(
      (eventPlayer) => eventPlayer.player.id === member.playerId
    );
  };

  const payForEvent = async (eventPlayer) => {
    if (eventPlayer && eventPlayer.currentFee > 0 && !eventPlayer.isPaid) {
      // setGettingPaymentStatus(true);

      try {
        const playerClubPaymentStatusResponse = await apiGetPlayerClubPaymentStatus(
          context.currentPlayer.id,
          context.currentClub.clubId
        );

        if (playerClubPaymentStatusResponse.success) {
          setPayEventFee(eventPlayer);
          // setGettingPaymentStatus(false);
        } else if (playerClubPaymentStatusResponse.redirectFlowUrl) {
          setRedirectFlowUrl(playerClubPaymentStatusResponse.redirectFlowUrl);
          // setGettingPaymentStatus(false);
        } else if (playerClubPaymentStatusResponse.message) {
          // setGettingPaymentStatusError(playerClubPaymentStatusResponse.message);
          // setGettingPaymentStatus(false);
        }
      } catch (e) {
        console.log(e);
        // setGettingPaymentStatus(false);
      }
    }
  };

  const submitPayment = async () => {
    if (payForEventPassword.length > 0) {
      if (payEventFee && !payEventFee.isPaid) {
        setSubmittingPayment(true);

        try {
          const submitPaymmentResponse = await apiSubmitPayment({
            eventId: match.params.eventId,
            targetPlayerId: payEventFee.player.id,
            password: payForEventPassword,
          });

          if (submitPaymmentResponse.success) {
            setSubmittingPayment(false);
            setPayEventFee(null);
            getEvent();
            setPayForEventPassword("");
          } else if (
            submitPaymmentResponse.errors &&
            submitPaymmentResponse.errors.length > 0
          ) {
            setSubmitPaymentError(submitPaymmentResponse.errors[0].reason);
            setSubmittingPayment(false);
          } else if (submitPaymmentResponse.redirectFlowUrl) {
            setPayEventFee(null);
            setRedirectFlowUrl(submitPaymmentResponse.redirectFlowUrl);
            setSubmittingPayment(false);
          } else {
            setSubmitPaymentError("An error occurred completing payment");
            setSubmittingPayment(false);
          }
        } catch (e) {
          setSubmitPaymentError(e);
          setPayForEventPassword("");
          setSubmittingPayment(false);
        }
      }
    }
  };

  const reorderClubPlayers = () => {
    setMembers([]);
    setSearchTerm("");

    setTimeout(() => {
      setMembers(
        members.sort((a, b) => {
          let aSelected = isPlayerSelected(a);
          let bSelected = isPlayerSelected(b);

          if (aSelected && !bSelected) {
            return -1;
          } else if (bSelected && !aSelected) {
            return 1;
          } else {
            let aName = `${a.firstName} ${a.lastName}`;
            let bName = `${b.firstName} ${b.lastName}`;

            if (aName !== bName) {
              return bName > aName ? -1 : 1;
            } else {
              return b.id > a.id ? -1 : 1;
            }
          }
        })
      );
    }, 10);
  };

  useEffect(() => {
    getMembers();
    getEvent();
  }, [getMembers, getEvent]);

  return (
    <PageWrapper
      history={history}
      showHeader={true}
      showSidebar={true}
      title="Event"
      showLoading={gettingMembers}
    >
      <Section>
        {event && event.type && event.team && (
          <div>
            <div className={classes.detail}>
              <Label title="NAME" />
              <div>{event.name}</div>
            </div>
            <div className={classes.detail}>
              <Label title="EVENT DATE" />
              <div>{moment.utc(event.eventDate).format("Do MMMM YYYY")}</div>
            </div>
            <div className={classes.detail}>
              <Label title="EVENT TYPE" />
              <div>{event.type.name}</div>
            </div>
            <div className={classes.detail}>
              <Label title="TEAM" />
              <div>{event.team.name}</div>
            </div>
          </div>
        )}
      </Section>

      <Section>
        <EventPlayersTable
          players={event.players}
          additionalButtons={
            (context.currentClub.isTreasurer ||
              event.isCaptainForEventTeam) && [
              {
                title: "Edit players",
                onPress: () => toggleShowAddPlayer(true),
              },
            ]
          }
          onEditEventFee={
            (context.currentClub.isTreasurer || event.isCaptainForEventTeam) &&
            ((eventPlayer) => setEditEventFee(eventPlayer))
          }
          onPayEventFee={(eventPlayer) => payForEvent(eventPlayer)}
          isTreasurerOrCaptain={
            context.currentClub.isTreasurer || event.isCaptainForEventTeam
          }
          myPlayer={context.currentPlayer}
        />
      </Section>

      {showAddPlayer && (
        <DialogModal
          title="Choose event players"
          onClose={() => toggleShowAddPlayer(false)}
          cancelText="Close"
        >
          <div className={classes.choosePlayerButtons}>
            <SingleLineTextInput
              placeholder="Search"
              value={searchTerm}
              onChange={(value) => setSearchTerm(value)}
            />
            <div>
              <Button title="REORDER" onClick={() => reorderClubPlayers()} />
            </div>
          </div>
          <ToggleEventPlayersForm
            members={members.filter(
              (member) =>
                `${member.firstName} ${member.lastName}`
                  .toLowerCase()
                  .indexOf(searchTerm.toLowerCase()) !== -1
            )}
            players={event.players}
            eventPlayers={event.players || []}
            onAddPlayer={(membership) => {
              addPlayerToEvent(membership);
            }}
            addingPlayer={addingPlayer}
            onRemovePlayer={(eventPlayer) => {
              removePlayerFromEvent(eventPlayer);
            }}
            deletingPlayer={deletingPlayer}
            onClose={() => toggleShowAddPlayer(false)}
            filterPaidPlayers={true}
          />
        </DialogModal>
      )}

      {editEventFee && (
        <DialogModal
          title="Edit event fee"
          onClose={() => setEditEventFee(null)}
          submitText="Update"
          onSubmit={() => updatePlayerEventFee()}
        >
          <EditEventFeeForm
            payload={editEventFee}
            storePayload={(value) => setEditEventFee(value)}
          />
        </DialogModal>
      )}

      {payEventFee && (
        <DialogModal
          title="Make payment"
          onClose={() => setPayEventFee(null)}
          onSubmit={() => submitPayment()}
          submitText="Pay"
          submitting={submittingPayment}
        >
          <PayForEventForm
            payload={{ ...payEventFee, password: payForEventPassword }}
            storePayload={(value) => setPayForEventPassword(value)}
            errorMessage={submitPaymentError}
          />
        </DialogModal>
      )}

      {redirectFlowUrl && (
        <DialogModal
          title="Setup your direct debit"
          onClose={() => setRedirectFlowUrl(null)}
        >
          <div>
            You haven't yet set up direct debits for Slate. Click{" "}
            <a href={redirectFlowUrl}>here</a> to do this now.
          </div>
        </DialogModal>
      )}
    </PageWrapper>
  );
}

export default withStyles(styles)(withState(EventPage));
