import React from "react";
import { withStyles } from "@material-ui/core/styles";
import { KeyboardTimePicker } from "@material-ui/pickers";

import Label from "../label/Label";

const styles = (theme) => ({
  timePickerInput: {
    marginBottom: "10px",
  },
  input: {
    width: "100%",
    border: "1px solid #D7D7D7",
    borderRadius: "3px",
    outline: "none",
    paddingLeft: "10px",
    paddingTop: "10px",
    paddingBottom: "10px",
    fontSize: "18px",
  },
  invalidInput: {
    border: "1px solid red",
  },
});

function timePickerInput({
  classes,
  style,
  value,
  label,
  type,
  showInvalidInput,
  onChange,
  required,
}) {
  // let inputClassName = classes.input;

  // if (showInvalidInput) {
  //   inputClassName += ` ${classes.invalidInput}`;
  // }

  return (
    <div className={classes.timePickerInput} style={style || {}}>
      {label && <Label title={label} required={required} />}
      <KeyboardTimePicker
        value={value}
        onChange={(value) => onChange(value)}
        variant="inline"
        format="HH:mm a"
        disableToolbar={false}
        inputVariant="outlined"
        style={{ marginRight: 10 }}
        invalidDateMessage=""
      />
    </div>
  );
}

export default withStyles(styles)(timePickerInput);
