import React from "react";
import { withStyles } from "@material-ui/core/styles";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";

import Label from "../label/Label";

const styles = (theme) => ({
  formControl: {
    width: "100%",
    marginBottom: 10,
  },
  checkboxItems: {
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "flex-start",
  },
  checkboxItem: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginRight: 10,
  },
  checkboxItemSelect: {
    height: 30,
    width: 30,
    border: "2px solid #3cc77b",
    marginRight: 5,
  },
});

function DropdownSelect({ classes, label, value, onSelect, items, placeholder, required }) {
  const handleChange = (event) => {
    onSelect(event.target.value);
  };

  const renderItems = () => {
    return items.map((item) => {
      return (
        <MenuItem key={item.value} value={item.value}>
          {item.label}
        </MenuItem>
      );
    });
  };

  return (
    <FormControl variant="outlined" className={classes.formControl}>
      {label && <Label title={label} required={required} />}
      <Select labelId="demo-simple-select-outlined-label" id="demo-simple-select-outlined" value={value} onChange={handleChange} placeholder={placeholder}>
        {placeholder && <MenuItem value={null}>{placeholder}</MenuItem>}
        {renderItems()}
      </Select>
    </FormControl>
  );
}

export default withStyles(styles)(DropdownSelect);
