import React from "react";
import { withStyles } from "@material-ui/core/styles";

const styles = (theme) => ({
  label: {
    fontSize: "12px",
    lineHeight: "14px",
    color: "#7B7B7B",
    marginBottom: "5px",
  },
});

function Label({ classes, title, required }) {
  return (
    <div className={classes.label}>
      {title}
      {required && "*"}
    </div>
  );
}

export default withStyles(styles)(Label);
