import React from "react";
import { Link } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";
import MenuIcon from "@material-ui/icons/Menu";

import { withState } from "../../../services/state";

import SlateLogo from "./Logo.png";

const styles = (theme) => ({
  pageHeader: {
    width: "100%",
    height: 80,
    backgroundColor: "#00823E",
    padding: 10,
    [theme.breakpoints.down("sm")]: {
      height: 50,
      display: "flex",
      alignItems: "center",
    },
  },
  pageHeaderLogo: {
    height: "calc(100% - 20px)",
    [theme.breakpoints.down("sm")]: {
      height: "100%",
      marginRight: 10,
    },
  },
  pageHeaderTitle: {
    color: "#fff",
    fontWeight: "bold",
    fontSize: 14,
    marginTop: 3,
  },
  menuToggle: {
    cursor: "pointer",
    marginRight: 10,
    color: "#fff",
  },
  pageHeaderContent: {
    textAlign: "center",
    height: "100%",
    display: "inline-block",
    [theme.breakpoints.down("sm")]: {
      display: "flex",
      alignItems: "center",
    },
  },
});

function PageHeader({
  classes,
  children,
  showHeader,
  showSidebar,
  setShowMinimizedSidebar,
  context,
}) {
  let logo = SlateLogo;
  if (context.organizationData) {
    logo = context.organizationData.logoLargeImageUrl;
  }

  return (
    <div
      className={classes.pageHeader}
      style={{
        backgroundColor: context.organizationData.colorPrimary || "blue",
      }}
    >
      {showSidebar && window.innerWidth < 960 && (
        <div
          className={classes.menuToggle}
          onClick={() => setShowMinimizedSidebar()}
        >
          <MenuIcon />
        </div>
      )}
      <Link to="/" style={{ height: "100%" }}>
        <div className={classes.pageHeaderContent}>
          <img className={classes.pageHeaderLogo} alt="Slate logo" src={logo} />
          <div className={classes.pageHeaderTitle}>Player App</div>
        </div>
      </Link>
    </div>
  );
}

export default withStyles(styles)(withState(PageHeader));
