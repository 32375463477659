import React from "react";

import AccountBalanceWallet from "@material-ui/icons/AccountBalanceWallet";
import Event from "@material-ui/icons/Event";
import Archive from "@material-ui/icons/Archive";
import LiveHelp from "@material-ui/icons/LiveHelp";
import Help from "@material-ui/icons/Help";
import Description from "@material-ui/icons/Description";

const NavbarConfig = [
  {
    title: "Outstanding Fees",
    route: "/outstanding-fees",
    icon: <AccountBalanceWallet />,
    treasurerOrCaptainOnly: true,
  },
  {
    title: "Events",
    route: "/events",
    icon: <Event />,
  },
  {
    title: "Archive",
    route: "/archive",
    icon: <Archive />,
  },
  {
    title: "How Slate Works",
    href: "https://slateapp.co.uk/how-slate-works/",
    icon: <Description />,
  },
  {
    title: "FAQs",
    href: "https://slateapp.co.uk/faqs/",
    icon: <Help />,
  },
  {
    title: "Help & Support",
    href: "https://slateapp.co.uk/contact-us/",
    icon: <LiveHelp />,
  },
];

export default NavbarConfig;
