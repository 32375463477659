import React from "react";
import { withStyles } from "@material-ui/core/styles";

import Table from "../../components/table/Table";

const styles = (theme) => ({
  section: {
    width: "100%",
    backgroundColor: "#fff",
  },
});

function EventPlayersTable({ classes, players, addButtonText, onAddButtonClick, hideSearch, onEditEventFee, onPayEventFee, additionalButtons, filterSelects, isTreasurerOrCaptain, myPlayer }) {
  const headings = [
    {
      label: "NAME",
      keys: [{
        key: "player",
        subKey: "firstName",
      }, {
        key: "player",
      subKey: "lastName",
      }],
      getTag: (data) => data.player.id === myPlayer.id && "You"
    },
    {
      label: "EVENT FEE",
      key: "currentFee",
      type: "currency",
      hidden: (data) => !isTreasurerOrCaptain && data.player.id !== myPlayer.id,
    },
    {
      label: "HAS PAID",
      key: "isPaid",
      type: "yesNo",
      hidden: (data) => !isTreasurerOrCaptain && data.player.id !== myPlayer.id,
    },
  ];

  return (
    <div className={classes.section}>
      <Table
        hideSearch={hideSearch}
        addButtonText={addButtonText}
        headings={headings}
        data={players}
        onAddButtonClick={(payload) => onAddButtonClick(payload)}
        rowActions={[
          { type: "edit", onClick: onEditEventFee ? (eventPlayer) => onEditEventFee(eventPlayer) : null, hideButton: (eventPlayer) => eventPlayer.isPaid && eventPlayer.currentFee !== 0, tooltip: "Edit fee" },
          {
            type: "pay",
            onClick: onPayEventFee ? (eventPlayer) => onPayEventFee(eventPlayer) : null,
            hideButton: (eventPlayer) => (!isTreasurerOrCaptain && eventPlayer.player.id !== myPlayer.id) || (eventPlayer.isPaid && eventPlayer.currentFee !== 0),
            tooltip: "Make payment"
          },
        ]}
        additionalButtons={additionalButtons}
        filterSelects={filterSelects}
      />
    </div>
  );
}

export default withStyles(styles)(EventPlayersTable);
