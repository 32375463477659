import React from "react";
import { withStyles } from "@material-ui/core/styles";
import { Link } from "react-router-dom";

import FormHeader from "../../components/formHeader/FormHeader";
import Checkbox from "../../components/checkbox/Checkbox";
import SingleLineTextInput from "../../components/singleLineTextInput/SingleLineTextInput";
import Button from "../../components/button/Button";
import SuccessMessage from "../../components/successMessage/SuccessMessage";
import ErrorMessage from "../../components/errorMessage/ErrorMessage";

const isInvalidEmail = (email) => !email;
const isInvalidPassword = (password) => !password || password.length < 6;

const styles = (theme) => ({
  section: {
    width: "100%",
    backgroundColor: "#fff",
  },
  acceptedInviteHeading: {
    fontSize: 25,
    marginBottom: 10,
  },
  acceptedInviteDescription: {
    fontSize: 16,
    marginBottom: 20,
  },
  loginButton: {
    marginBottom: 30,
  },
  appLink: {
    cursor: "pointer",
    display: "inline-block",
    marginRight: "10px",
  },
  appLinkImage: {
    height: 40,
  },
});

function AcceptInviteForm({
  classes,
  onSubmit,
  message,
  error,
  submitting,
  submitText,
  inviteAccepted,
}) {
  const [submitAttempted, setSubmitAttempted] = React.useState(false);
  const [payload, storePayload] = React.useState({});

  if (inviteAccepted) {
    return (
      <div>
        <div className={classes.acceptedInvite}>
          <div className={classes.acceptedInviteHeading}>
            You have successfully setup your Slate account.
          </div>
          <div className={classes.acceptedInviteDescription}>
            Slate allows you to pay fees you owe to the club or
            organisation(ranging from items such as club events, annual subs,
            club kit etc.), and you can do so via the Slate mobile app, or on
            the web.
          </div>
          <div className={classes.acceptedInviteDescription}>
            You can setup your payment method by clicking pay on an event and
            following the link.
          </div>
        </div>
        <div className={classes.loginButton}>
          <Link to="/">
            <Button title="Login" />
          </Link>
        </div>
        <div>
          <a href="https://itunes.apple.com/gb/app/slateapp/id1103301760?mt=8">
            <div className={classes.appLink}>
              <img
                className={classes.appLinkImage}
                alt="App Store"
                src="https://linkmaker.itunes.apple.com/assets/shared/badges/en-us/appstore-lrg.svg"
              />
            </div>
          </a>
          <a href="https://play.google.com/store/apps/details?id=com.ralph.slate&pcampaignid=MKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1">
            <div className={classes.appLink}>
              <img
                className={classes.appLinkImage}
                alt="Google Play Store"
                src="https://s3.eu-west-2.amazonaws.com/slate-logos/google-play-badge.png"
              />
            </div>
          </a>
        </div>
      </div>
    );
  }

  return (
    <div className={classes.section}>
      <div className={classes.setupInformation}>
        <FormHeader title="Accept invite" />
      </div>
      <SingleLineTextInput
        label="EMAIL ADDRESS"
        onChange={(value) => storePayload({ ...payload, emailAddress: value })}
        showInvalidInput={
          submitAttempted && isInvalidEmail(payload.emailAddress)
        }
        style={{ width: "100%" }}
        type="text"
      />
      <SingleLineTextInput
        label="NEW PASSWORD"
        onChange={(value) => storePayload({ ...payload, password: value })}
        showInvalidInput={
          submitAttempted && isInvalidPassword(payload.password)
        }
        style={{ width: "100%" }}
        type="password"
      />
      <div>
        <Checkbox
          label="Sign me up to hear about the latest Slate news"
          value={payload.signUpToNewsletter}
          onClick={() =>
            storePayload({
              ...payload,
              signUpToNewsletter: !payload.signUpToNewsletter,
            })
          }
        />
        <Checkbox
          label={
            <div>
              I agree to the Slate{" "}
              <a href="https://slateapp.co.uk/terms-conditions/">terms</a> and{" "}
              <a href="https://slateapp.co.uk/privacy-policy/">
                privacy policy
              </a>
            </div>
          }
          value={payload.agreeToTerms}
          onClick={() =>
            storePayload({ ...payload, agreeToTerms: !payload.agreeToTerms })
          }
        />
      </div>
      <ErrorMessage message={error} />
      <SuccessMessage message={message} />
      <Button
        title={submitText || "Submit"}
        onClick={() => {
          if (!submitting) {
            setSubmitAttempted(true);
            onSubmit(payload);
          }
        }}
        submitting={submitting}
      />
    </div>
  );
}

export default withStyles(styles)(AcceptInviteForm);
