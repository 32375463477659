import React from "react";
import { Link } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";
import ExitToApp from "@material-ui/icons/ExitToApp";
import Sync from "@material-ui/icons/Sync";

import { withState } from "../../../services/state";
import sidebarNavigation from "../../../services/navbar";

const styles = (theme) => ({
  pageSidebar: {
    height: "100%",
    width: 300,
    backgroundColor: "#393A39",
    color: "#fff",
    overflow: "scroll",
    [theme.breakpoints.down("sm")]: {
      zIndex: 1000,
      position: "absolute",
      width: 270,
    },
  },
  currentView: {
    padding: 20,
  },
  currentlySelected: {
    margin: "10px 0",
    cursor: "pointer",
    minHeight: "40px",
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
  },
  clubLogo: {
    height: 50,
  },
  selectItem: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    margin: "10px 0",
    cursor: "pointer",
    minHeight: "40px",
    opacity: 0.4,
  },
  nameInitial: {
    height: 40,
    width: 40,
    marginRight: 10,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    background: "grey",
  },
  link: {
    textDecoration: "none !important",
  },
  navigationItem: {
    borderBottom: "1px solid #515151",
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    padding: "13px 10px",
    borderLeft: "3px solid #393A39",
    cursor: "pointer",
    color: "#fff",
    opacity: 0.5,
  },
  navigationItemActive: {
    opacity: 1,
    borderLeft: "3px solid #00823E",
  },
  navigationItemTitle: {
    paddingBottom: 3,
    marginLeft: 5,
  },
});

const SidebarNavigationItem = ({ classes, navigationItem }) => {
  let inputClassName = classes.navigationItem;

  const activeLink = window.location.pathname === navigationItem.route;
  if (activeLink) {
    inputClassName += ` ${classes.navigationItemActive}`;
  }

  if (navigationItem.href) {
    return (
      <a className={classes.link} href={navigationItem.href}>
        <div className={inputClassName}>
          <div>{navigationItem.icon}</div>
          <div className={classes.navigationItemTitle}>
            {navigationItem.title}
          </div>
        </div>
      </a>
    );
  }

  return (
    <Link className={classes.link} to={navigationItem.route}>
      <div className={inputClassName}>
        <div>{navigationItem.icon}</div>
        <div className={classes.navigationItemTitle}>
          {navigationItem.title}
        </div>
      </div>
    </Link>
  );
};

const renderNavigation = ({ classes, context }) => {
  return sidebarNavigation
    .filter((navigationItem) => {
      return (
        !navigationItem.treasurerOrCaptainOnly ||
        context.currentClub.isTreasurer ||
        context.currentClub.isCaptain
      );
    })
    .map((navigationItem) => {
      return (
        <SidebarNavigationItem
          key={navigationItem.title}
          classes={classes}
          navigationItem={navigationItem}
        />
      );
    });
};

function PageSidebar({
  history,
  classes,
  context,
  dispatch,
  showMinimizedSidebar,
}) {
  const currentPlayerName =
    context.currentPlayer &&
    context.currentPlayer.firstName &&
    context.currentPlayer.lastName &&
    `${context.currentPlayer.firstName} ${context.currentPlayer.lastName}`;
  const currentClubName = context.currentClub && context.currentClub.name;

  const showSelectPlayer = () => {
    dispatch({
      type: "showSelectPlayer",
    });
  };

  const logout = () => {
    dispatch({
      type: "clearToken",
    });
    dispatch({
      type: "clearSessionVariables",
    });
    history.push("/");
  };

  if (window.innerWidth < 960 && !showMinimizedSidebar) {
    return <div />;
  }

  return (
    <div className={classes.pageSidebar}>
      <div className={classes.currentView}>
        {currentPlayerName && (
          <div
            className={classes.currentlySelected}
            onClick={() => showSelectPlayer()}
          >
            <img
              alt="Club logo"
              className={classes.clubLogo}
              src={context.currentClub.logoUrl}
            />
            <div>
              <div>{currentPlayerName}</div>
              <div>{currentClubName}</div>
            </div>
          </div>
        )}
        {!currentPlayerName && (
          <div className={classes.selectItem}>Select a player</div>
        )}
        {context.profile &&
          context.profile.players &&
          context.profile.players.length > 0 && (
            <div
              className={classes.selectItem}
              onClick={() => showSelectPlayer()}
            >
              <Sync /> Switch accounts
            </div>
          )}
      </div>
      {renderNavigation({ classes, context })}
      <div>
        {/* <div className={classes.navigationItem} onClick={() => logout()}>
          <div>
            <AccountCircle />
          </div>
          <div className={classes.navigationItemTitle}>Edit your player details</div>
        </div>
        <div className={classes.navigationItem} onClick={() => logout()}>
          <div>
            <Email />
          </div>
          <div className={classes.navigationItemTitle}>Edit your email</div>
        </div> */}
        <div className={classes.navigationItem} onClick={() => logout()}>
          <div>
            <ExitToApp />
          </div>
          <div className={classes.navigationItemTitle}>Logout</div>
        </div>
      </div>
    </div>
  );
}

export default withStyles(styles)(withState(PageSidebar));
