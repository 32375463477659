import React from "react";
import { withStyles } from "@material-ui/core/styles";
import Info from "@material-ui/icons/Info";

import PageSidebar from "../pageSidebar/PageSidebar";

const styles = (theme) => ({
  pageWithHeaderAndSidebar: {
    width: "100%",
    height: "calc(100% - 80px)",
    display: "flex",
  },
  pageWithHeader: {
    width: "100%",
    height: "calc(100% - 80px)",
  },
  pageWithSidebar: {
    width: "100%",
    height: "100%",
    display: "flex",
  },
  page: {
    width: "100%",
    height: "100%",
  },
  pageContent: {
    width: "100%",
    height: "100%",
    padding: 40,
    backgroundColor: "#f8f8f8",
    overflowY: "scroll",
    [theme.breakpoints.down("sm")]: {
      padding: 20,
    },
  },
  title: {
    color: "#000000",
    fontSize: "18px",
    lineHeight: "21px",
    marginBottom: "20px",
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
  },
  infoClick: {
    marginLeft: 5,
    cursor: "pointer",
  },
});

function PageWrapper({ history, classes, children, showHeader, showSidebar, title, showMinimizedSidebar, showOnInfoClick, onInfoClick }) {
  const renderContent = () => {
    return (
      <div className={classes.page}>
        {title && (
          <div className={classes.title}>
            {title}
            {showOnInfoClick && (
              <div className={classes.infoClick} onClick={() => onInfoClick()}>
                <Info />
              </div>
            )}
          </div>
        )}
        {children}
      </div>
    );
  };

  if (showHeader && showSidebar) {
    return (
      <div className={classes.pageWithHeaderAndSidebar}>
        <PageSidebar history={history} showMinimizedSidebar={showMinimizedSidebar} />
        <div className={classes.pageContent}>{renderContent()}</div>
      </div>
    );
  } else if (showHeader) {
    return (
      <div className={classes.pageWithHeader}>
        <div className={classes.pageContent}>{renderContent()}</div>
      </div>
    );
  } else if (showSidebar) {
    return (
      <div className={classes.pageWithSidebar}>
        <PageSidebar history={history} showMinimizedSidebar={showMinimizedSidebar} />
        <div className={classes.pageContent}>{renderContent()}</div>
      </div>
    );
  } else {
    return (
      <div className={classes.page}>
        <div className={classes.pageContent}>{renderContent()}</div>
      </div>
    );
  }
}

export default withStyles(styles)(PageWrapper);
