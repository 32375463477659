import React from "react";
import { withStyles } from "@material-ui/core/styles";
import Search from "@material-ui/icons/Search";
import CircularProgress from "@material-ui/core/CircularProgress";

import Label from "../label/Label";

const styles = (theme) => ({
  singleLineTextInput: {
    marginBottom: "10px",
  },
  input: {
    width: "100%",
    border: "1px solid #D7D7D7",
    borderRadius: "3px",
    outline: "none",
    paddingLeft: "10px",
    paddingTop: "10px",
    paddingBottom: "10px",
    fontSize: "18px",
  },
  invalidInput: {
    border: "1px solid red",
  },
  inputWrapper: {
    display: "flex",
    alignItems: "center",
    position: "relative",
  },
  startAdornment: {
    marginRight: 5,
    fontSize: 23,
  },
  iconWrapper: {
    position: "absolute",
    right: 0,
    background: "#3cc87b",
    color: "white",
    height: "100%",
    borderTopRightRadius: "3px",
    borderBottomRightRadius: "3px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: 43,
    cursor: "pointer",
  },
  spinner: {
    width: "20 !important",
    height: "20 !important",
    color: "#fff",
  },
});

function SingleLineTextInput({ classes, style, value, label, type, showInvalidInput, onChange, placeholder, startAdornment, required, button, onButtonClick, buttonSubmitting }) {
  let inputClassName = classes.input;

  if (showInvalidInput) {
    inputClassName += ` ${classes.invalidInput}`;
  }

  const renderButtonIcon = () => {
    if (buttonSubmitting) {
      return <CircularProgress className={classes.spinner} />;
    }

    switch (button) {
      case "search":
        return <Search />;
      default:
        return null;
    }
  };

  return (
    <div className={classes.singleLineTextInput} style={style || {}}>
      {label && <Label title={label} required={required} />}
      <div className={classes.inputWrapper}>
        {startAdornment && <div className={classes.startAdornment}>{startAdornment}</div>}
        <input className={inputClassName} value={value} type={type || "text"} onChange={(event) => onChange(event.target.value)} placeholder={placeholder} />
        {button && (
          <div className={classes.iconWrapper} onClick={() => onButtonClick()}>
            {renderButtonIcon()}
          </div>
        )}
      </div>
    </div>
  );
}

export default withStyles(styles)(SingleLineTextInput);
