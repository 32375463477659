import React from "react";
import { withStyles } from "@material-ui/core/styles";

import { withState } from "../../../services/state";
import {
  getProfile as apiGetProfile,
  register as apiRegister,
} from "../../../services/api";

import PageWrapper from "../../components/pageWrapper/PageWrapper";
import AuthenticationFlow from "../../components/authenticationFlow/AuthenticationFlow";

import RegisterForm from "../../forms/registerForm/RegisterForm";

const styles = (theme) => ({
  paragraph: {
    marginBottom: 20,
  },
});

const RegisterPage = ({ classes, history, dispatch, context }) => {
  const [currentStep, setCurrentStep] = React.useState(0);
  const [registering, setRegistering] = React.useState(false);
  const [registerError, setRegisterError] = React.useState("");

  const register = async (payload) => {
    if (!payload.emailAddress || !payload.password) {
      setRegisterError("Please complete all fields");
      return false;
    }

    if (payload.emailAddress !== payload.confirmEmailAddress) {
      setRegisterError("Email addresses do not match");
      return false;
    }

    if (!/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}$/.test(payload.password)) {
      setRegisterError("Password does not match requirements");
      return false;
    }

    if (payload.password !== payload.confirmPassword) {
      setRegisterError("Passwords do not match");
      return false;
    }

    if (!payload.agreeToTerms) {
      setRegisterError("Please agree to the terms and conditions");
      return false;
    }

    setRegisterError("");

    try {
      setRegistering(true);
      const registerData = await apiRegister({
        ...payload,
        organizationId: context.organizationData.guid,
      });
      if (registerData.success) {
        dispatch({
          type: "siteLoaded",
          tokens: {
            accessToken: registerData.accessToken,
            refreshToken: registerData.refreshToken,
          },
          loggedIn: true,
          loaded: false,
        });

        await getProfile();
      } else if (registerData.errors) {
        setRegisterError(registerData.errors[0].reason);
      } else {
        setRegisterError("An error occurred trying to register");
      }
      setRegistering(false);
    } catch (e) {
      setRegistering(false);
      setRegisterError(e.message);
    }
  };

  const getProfile = async () => {
    try {
      const data = await apiGetProfile();

      if (data) {
        if (data && data.players && data.players.length > 0) {
          dispatch({
            type: "storeSessionVariables",
            currentPlayer: data.players[0],
          });
        }

        dispatch({
          type: "storeProfile",
          profile: data,
        });
      }
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <PageWrapper history={history} showHeader={true}>
      <AuthenticationFlow
        currentStep={currentStep}
        onGoBack={() => setCurrentStep(currentStep - 1)}
        steps={[
          <RegisterForm
            onSubmit={(payload) => {
              register(payload);
            }}
            registering={registering}
            registerError={registerError}
            submitText="Register"
          />,
        ]}
      />
    </PageWrapper>
  );
};

export default withStyles(styles)(withState(RegisterPage));
