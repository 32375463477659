import React from "react";
import { withStyles } from "@material-ui/core/styles";

import Table from "../../components/table/Table";

const styles = (theme) => ({
  section: {
    width: "100%",
    backgroundColor: "#fff",
  },
});

const headings = [
  {
    label: "EVENT NAME",
    key: "eventName",
  },
  {
    label: "EVENT DATE",
    key: "date",
    type: "date",
  },
  {
    label: "TEAM NAME",
    key: "teamName",
  },
  {
    label: "EVENT FEE",
    key: "eventFee",
    type: "currency",
  },
];

function OutstandingFeesTable({
  classes,
  data,
  addButtonText,
  onAddButtonClick,
  hideSearch,
  onViewPlayerFees,
  onPayEventFee,
  additionalButtons,
  filterSelects,
}) {
  return (
    <div className={classes.section}>
      <Table
        hideSearch={hideSearch}
        addButtonText={addButtonText}
        headings={headings}
        data={data}
        onAddButtonClick={(payload) => onAddButtonClick(payload)}
        rowActions={[
          {
            type: "fee-breakdown",
            onClick: onViewPlayerFees
              ? (player) => onViewPlayerFees(player)
              : null,
            hideButton: (player) =>
              !player.overdueEventFees || player.overdueEventFees.length === 0,
          },
        ]}
        additionalButtons={additionalButtons}
        filterSelects={filterSelects}
      />
    </div>
  );
}

export default withStyles(styles)(OutstandingFeesTable);
