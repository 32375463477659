import React, { useEffect, useCallback } from "react";
import { withStyles } from "@material-ui/core/styles";

import { withState } from "../../../services/state";

import {
  getMembers as apiGetMembers,
  getEvent as apiGetEvent,
  updateEvent as apiUpdateEvent,
  addPlayerToEvent as apiAddPlayerToEvent,
  removePlayerFromEvent as apiRemovePlayerFromEvent,
  updatePlayerEventFee as apiUpdatePlayerEventFee,
  getTeams as apiGetTeams,
  getEventTypes as apiGetEventTypes,
  selectLastTeamForEvent as apiSelectLastTeamForEvent,
} from "../../../services/api";

import PageWrapper from "../../components/pageWrapper/PageWrapper";
import Section from "../../components/section/Section";
import DialogModal from "../../components/dialogModal/DialogModal";
import Button from "../../components/button/Button";
import YesNoDialogModal from "../../components/yesNoDialogModal/YesNoDialogModal";
import SingleLineTextInput from "../../components/singleLineTextInput/SingleLineTextInput";

import AddEventForm from "../../forms/addEventForm/AddEventForm";
import EventPlayersTable from "../../tables/eventPlayersTable/EventPlayersTable";
import ToggleEventPlayersForm from "../../forms/toggleEventPlayersForm/ToggleEventPlayersForm";
import EditEventFeeForm from "../../forms/editEventFeeForm/EditEventFeeForm";

const styles = (theme) => ({
  root: {
    width: "100%",
  },
  formActions: {
    textAlign: "right",
  },
  choosePlayerButtons: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    marginBottom: 10,
    "& > div": {
      margin: "0 5px",
    },
    [theme.breakpoints.down("sm")]: {
      display: "block",
      "& > div": {
        marginBottom: "10px",
      },
    },
  },
});

const getPlayerType = (currentClub) => {
  if (currentClub.isTreasurer) return "treasurer";
  if (currentClub.isCaptain) return "captain";
  return "player";
};

const event_action = {
  captain:
    "Here you can enter details of your upcoming fixtures and pick your teams. If you hit the Slate logo to go back to the events list, the details will be saved and you or the club Treasurer can still edit them. Please note that once you CONFIRM an event, the details cannot be changed. Payments from your players will only be collected once an event is confirmed.",
  treasurer:
    "Here you can enter details of your upcoming fixtures and pick your teams. If you hit the Slate logo to go back to the events list, the details will be saved and you or the Team Manager can still edit them. Please note that once you CONFIRM an event, the details cannot be changed. Payments from your players will only be collected once an event is confirmed.",
};

function EditEventPage({ history, match, classes, dispatch, context }) {
  const [showAddPlayer, toggleShowAddPlayer] = React.useState(false);
  const [searchTerm, setSearchTerm] = React.useState("");
  const [members, setMembers] = React.useState([]);
  const [event, setEvent] = React.useState(true);
  const [initialEventLoading, setInitialEventLoading] = React.useState(true);
  // const [addingPlayerError, setAddPlayerError] = React.useState(true);
  const [addingPlayer, setAddingPlayer] = React.useState(true);
  // const [deletePlayerError, setDeletePlayerError] = React.useState(true);
  const [deletingPlayer, setDeletingPlayer] = React.useState(true);
  const [gettingTeams, setGettingTeams] = React.useState(true);
  const [teams, setTeams] = React.useState([]);
  const [gettingEventTypes, setGettingEventTypes] = React.useState(true);
  const [eventTypes, setEventTypes] = React.useState([]);
  const [editEventFee, setEditEventFee] = React.useState(null);
  const [updatingEvent, setUpdatingEvent] = React.useState(false);
  const [confirmingEvent, setConfirmingEvent] = React.useState(false);
  const [selectingLastTeam, setSelectingLastTeam] = React.useState(false);
  const [showEditEventInfo, setShowEditEventInfo] = React.useState(false);
  const [showConfirmEvent, setShowConfirmEvent] = React.useState(false);
  const [confirmEventError, setConfirmEventError] = React.useState("");

  const getMembers = useCallback(async () => {
    try {
      if (!context.currentClub.clubId) {
        return false;
      }

      const data = await apiGetMembers(
        context.currentClub.clubId,
        context.currentPlayer.id
      );
      if (data && !data.reason) {
        setMembers(data);
      }
    } catch (e) {
      console.log(e);
    }
  }, [context.currentClub.clubId, context.currentPlayer.id]);

  const getEvent = useCallback(async () => {
    try {
      const data = await apiGetEvent(match.params.eventId);
      if (!event.id) {
        setInitialEventLoading(false);
      }
      if (data && !data.reason) {
        setEvent({
          ...data,
          teamId: data && data.team && data.team.id,
          eventTypeId: data && data.type && data.type.id,
        });
      }
    } catch (e) {
      console.log(e);
    }
  }, [match.params.eventId, event.id]);

  const confirmEvent = async () => {
    try {
      setConfirmingEvent(true);
      await apiUpdateEvent(match.params.eventId, {
        ...event,
        isConfirmed: true,
      });
      setConfirmingEvent(false);
      setShowConfirmEvent(false);
      history.push("/");
    } catch (e) {
      console.log(e);
      setConfirmEventError("An error occurred confirming the event");
      setConfirmingEvent(false);
    }
  };

  const updateEvent = async () => {
    try {
      setUpdatingEvent(true);
      const data = await apiUpdateEvent(match.params.eventId, event);
      if (data && !data.reason) {
        await this.getEvent();
      }
      setUpdatingEvent(false);
    } catch (e) {
      console.log(e);
      setUpdatingEvent(false);
    }
  };

  const getTeams = useCallback(async () => {
    if (
      !context.currentClub ||
      !context.currentClub.clubId ||
      !context.currentPlayer ||
      !context.currentPlayer.id
    ) {
      return false;
    }

    try {
      setGettingTeams(true);
      const data = await apiGetTeams(
        context.currentClub.clubId,
        context.currentPlayer.id
      );
      if (data && !data.reason) {
        let teams = data;
        if (context.currentPlayer.isCaptain) {
          teams = data.filter((team) => team.isTeamCaptain);
        }
        setTeams(teams);
      }
      setGettingTeams(false);
    } catch (e) {
      console.log(e);
      setGettingTeams(false);
    }
  }, [context.currentClub, context.currentPlayer]);

  const getEventTypes = useCallback(async () => {
    if (
      !context.currentClub ||
      !context.currentClub.clubId ||
      !context.currentPlayer ||
      !context.currentPlayer.id
    ) {
      return false;
    }

    try {
      setGettingEventTypes(true);
      const data = await apiGetEventTypes(
        context.currentClub.clubId,
        context.currentPlayer.id
      );
      if (data && !data.reason) {
        setEventTypes(data);
      }
      setGettingEventTypes(false);
    } catch (e) {
      console.log(e);
      setGettingEventTypes(false);
    }
  }, [context.currentClub, context.currentPlayer]);

  const addPlayerToEvent = async (member) => {
    try {
      setAddingPlayer(member.playerId);
      await apiAddPlayerToEvent(match.params.eventId, member.playerId);
      setAddingPlayer(false);
      getEvent();
    } catch (e) {
      setAddingPlayer(false);
      // setAddPlayerError(e.message);
    }
  };

  const removePlayerFromEvent = async (eventPlayer) => {
    try {
      setDeletingPlayer(eventPlayer.player.id);
      await apiRemovePlayerFromEvent(
        match.params.eventId,
        eventPlayer.player.id
      );
      getEvent();
      setDeletingPlayer(false);
    } catch (e) {
      setDeletingPlayer(false);
      // setDeletePlayerError(e.message);
    }
  };

  const updatePlayerEventFee = async () => {
    try {
      await apiUpdatePlayerEventFee(
        match.params.eventId,
        editEventFee.player.id,
        editEventFee.currentFee
      );
      getEvent();
      setEditEventFee(null);
    } catch (e) {
      // setDeletePlayerError(e.message);
    }
  };

  const selectLastTeam = async () => {
    setSelectingLastTeam(true);
    await apiSelectLastTeamForEvent(match.params.eventId);
    await getEvent();
    setSelectingLastTeam(false);
  };

  const isPlayerSelected = (member) => {
    return event.players.find(
      (eventPlayer) => eventPlayer.player.id === member.playerId
    );
  };

  const reorderClubPlayers = () => {
    setMembers([]);
    setSearchTerm("");

    setTimeout(() => {
      setMembers(
        members.sort((a, b) => {
          let aSelected = isPlayerSelected(a);
          let bSelected = isPlayerSelected(b);

          if (aSelected && !bSelected) {
            return -1;
          } else if (bSelected && !aSelected) {
            return 1;
          } else {
            let aName = `${a.firstName} ${a.lastName}`;
            let bName = `${b.firstName} ${b.lastName}`;

            if (aName !== bName) {
              return bName > aName ? -1 : 1;
            } else {
              return b.id > a.id ? -1 : 1;
            }
          }
        })
      );
    }, 10);
  };

  useEffect(() => {
    getMembers();
    getEvent();
    getTeams();
    getEventTypes();
  }, [getMembers, getEvent, getTeams, getEventTypes]);

  return (
    <PageWrapper
      history={history}
      showHeader={true}
      showSidebar={true}
      title="Edit event"
      showLoading={gettingTeams || gettingEventTypes || initialEventLoading}
      onInfoClick={
        event_action[getPlayerType(context.currentClub)]
          ? () => setShowEditEventInfo(true)
          : null
      }
    >
      <Section>
        <div className={classes.formActions}>
          <Button
            title="Confirm"
            onClick={() => {
              setShowConfirmEvent(true);
            }}
            submitting={confirmingEvent}
          />
        </div>
        <AddEventForm
          payload={event}
          storePayload={setEvent}
          teams={teams}
          eventTypes={eventTypes}
          members={members}
        />
        <div className={classes.formActions}>
          <Button
            title="Save"
            onClick={() => {
              updateEvent();
            }}
            submitting={updatingEvent}
          />
        </div>
      </Section>
      <Section>
        <EventPlayersTable
          players={event.players}
          additionalButtons={
            (context.currentClub.isTreasurer ||
              event.isCaptainForEventTeam) && [
              {
                title: "Edit players",
                onPress: () => toggleShowAddPlayer(true),
              },
            ]
          }
          onEditEventFee={(eventPlayer) => setEditEventFee(eventPlayer)}
          isTreasurerOrCaptain={
            context.currentClub.isTreasurer || event.isCaptainForEventTeam
          }
          myPlayer={context.currentPlayer}
        />
      </Section>

      {showAddPlayer && (
        <DialogModal
          title="Choose event players"
          onClose={() => toggleShowAddPlayer(false)}
          cancelText="Close"
        >
          <div className={classes.choosePlayerButtons}>
            <SingleLineTextInput
              placeholder="Search"
              value={searchTerm}
              onChange={(value) => setSearchTerm(value)}
            />
            <div>
              <Button
                title="REORDER"
                onClick={() => reorderClubPlayers()}
                style={{ marginRight: 10 }}
              />
              <Button
                title="LAST TEAM"
                onClick={() => selectLastTeam()}
                submitting={selectingLastTeam}
              />
            </div>
          </div>
          <ToggleEventPlayersForm
            members={members.filter(
              (member) =>
                `${member.firstName} ${member.lastName}`
                  .toLowerCase()
                  .indexOf(searchTerm.toLowerCase()) !== -1
            )}
            players={event.players}
            eventPlayers={event.players || []}
            onAddPlayer={(membership) => {
              addPlayerToEvent(membership);
            }}
            addingPlayer={addingPlayer}
            onRemovePlayer={(eventPlayer) => {
              removePlayerFromEvent(eventPlayer);
            }}
            deletingPlayer={deletingPlayer}
            onClose={() => toggleShowAddPlayer(false)}
          />
        </DialogModal>
      )}

      {editEventFee && (
        <DialogModal
          title="Edit event fee"
          onClose={() => setEditEventFee(null)}
          submitText="Update"
          onSubmit={() => updatePlayerEventFee()}
        >
          <EditEventFeeForm
            payload={editEventFee}
            storePayload={(value) => setEditEventFee(value)}
          />
        </DialogModal>
      )}

      {showEditEventInfo && (
        <DialogModal
          title="Your events"
          onClose={() => setShowEditEventInfo(null)}
          cancelText="Close"
        >
          <div>{event_action[getPlayerType(context.currentClub)]}</div>
        </DialogModal>
      )}

      {showConfirmEvent && (
        <YesNoDialogModal
          title="You should only confirm the fixture/event after the match has taken place. You can make adjustments after you have confirmed the fixture, but it is less work if you get it right this time round."
          onClose={() => setShowConfirmEvent(false)}
          onSubmit={() => confirmEvent()}
          submitting={confirmingEvent}
          error={confirmEventError}
        />
      )}
    </PageWrapper>
  );
}

export default withStyles(styles)(withState(EditEventPage));
