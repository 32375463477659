import React, { useEffect, useCallback } from "react";
import { Link } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";
import qs from "qs";

import { withState } from "../../../services/state";
import { completeDurectDebitSetup as apiCompleteDurectDebitSetup } from "../../../services/api";

import PageWrapper from "../../components/pageWrapper/PageWrapper";
import AuthenticationFlow from "../../components/authenticationFlow/AuthenticationFlow";

const styles = (theme) => ({
  returnToAccount: {
    marginTop: "20px",
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  returnToApp: {
    marginTop: "20px",
    display: "none",
    [theme.breakpoints.down("sm")]: {
      display: "block",
    },
  },
});

const CompleteMandateSetupPage = ({ history, location, classes }) => {
  const completeMandateSetup = useCallback(async () => {
    console.log(history, location);
    const queryParams = qs.parse(location.search, {
      ignoreQueryPrefix: true,
    });

    try {
      await apiCompleteDurectDebitSetup(queryParams.redirect_flow_id);
    } catch (e) {
      console.log(e);
    }
  }, [history, location]);

  useEffect(() => {
    completeMandateSetup();
  }, [completeMandateSetup]);

  return (
    <PageWrapper history={history} showHeader={true}>
      <AuthenticationFlow
        currentStep={0}
        steps={[
          <div>
            <div style={{ marginBottom: 20 }}>Complete!</div>
            <div>
              Thank you for setting up your Gocardless account, you can now pay
              for events.
            </div>
            <div className={classes.returnToAccount}>
              <Link to="/">Return to your account</Link>
            </div>
            <div className={classes.returnToApp}>
              Return to the app to start paying for events
            </div>
          </div>,
        ]}
      />
    </PageWrapper>
  );
};

export default withStyles(styles)(withState(CompleteMandateSetupPage));
