import React from "react";
import { withStyles } from "@material-ui/core/styles";

import FormHeader from "../../components/formHeader/FormHeader";
import SingleLineTextInput from "../../components/singleLineTextInput/SingleLineTextInput";
import ErrorMessage from "../../components/errorMessage/ErrorMessage";

const isInvalidPassword = (name) => !name;

const styles = (theme) => ({
  section: {
    width: "100%",
    backgroundColor: "#fff",
  },
});

function PayForEventForm({ classes, payload, storePayload, errorMessage }) {
  const [submitAttempted, setSubmitAttempted] = React.useState(false);

  return (
    <div className={classes.section}>
      <div className={classes.setupInformation}>
        <FormHeader title={`£${payload.currentFee.toFixed(2)}`} />
      </div>
      <SingleLineTextInput
        label="PASSWORD"
        value={payload.password}
        onChange={(value) => {
          setSubmitAttempted(true);
          storePayload(value);
        }}
        showInvalidInput={submitAttempted && isInvalidPassword(payload.password)}
        type="password"
      />
      <ErrorMessage message={errorMessage} />
    </div>
  );
}

export default withStyles(styles)(PayForEventForm);
