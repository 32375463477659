import React from "react";
import { withStyles } from "@material-ui/core/styles";

import FormHeader from "../../components/formHeader/FormHeader";
import SingleLineTextInput from "../../components/singleLineTextInput/SingleLineTextInput";
import Button from "../../components/button/Button";

const isInvalidName = (name) => !name;

const styles = (theme) => ({
  section: {
    width: "100%",
    backgroundColor: "#fff",
  },
  modalButtons: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    marginTop: 20,
  },
});

function AddMemberForm({ classes, payload, storePayload, onSubmit, errorMessage, submitting, onBack }) {
  const [submitAttempted, setSubmitAttempted] = React.useState(false);

  return (
    <div className={classes.section}>
      <div className={classes.setupInformation}>
        <FormHeader title={"Add player"} />
      </div>
      <SingleLineTextInput
        label="FIRST NAME"
        value={payload.firstName}
        onChange={(value) => storePayload({ ...payload, firstName: value })}
        showInvalidInput={submitAttempted && isInvalidName(payload.firstName)}
      />
      <SingleLineTextInput
        label="LAST NAME"
        value={payload.lastName}
        onChange={(value) => storePayload({ ...payload, lastName: value })}
        showInvalidInput={submitAttempted && isInvalidName(payload.lastName)}
      />
      <div className={classes.modalButtons}>
        <div>
          {onBack && (
            <Button
              title="Back"
              onClick={() => {
                onBack();
              }}
              submitting={submitting}
              style={{ backgroundColor: "rgb(243, 243, 243)", color: "#222" }}
            />
          )}
        </div>
        <div>
          {onSubmit && (
            <Button
              title="Next"
              onClick={() => {
                setSubmitAttempted(true);
                onSubmit(payload);
              }}
            />
          )}
        </div>
      </div>
    </div>
  );
}

export default withStyles(styles)(AddMemberForm);
