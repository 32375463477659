import React, { useEffect } from "react";
import { withStyles } from "@material-ui/core/styles";
import CircularProgress from "@material-ui/core/CircularProgress";

import { withState } from "../../../services/state";
import {
  loginToClub as apiLoginToClub,
  getProfile as apiGetProfile,
  updatePlayerDetails as apiUpdatePlayerDetails,
  resendVerificationEmail as apiResendVerificationEmail,
} from "../../../services/api";

import PageHeader from "../pageHeader/PageHeader";
import PageContent from "../pageContent/PageContent";
import DialogModal from "../dialogModal/DialogModal";
import Button from "../button/Button";

import SelectPlayerForm from "../../forms/selectPlayerForm/SelectPlayerForm";
import RegistrationInfoForm from "../../forms/registrationInfoForm/RegistrationInfoForm";

const styles = (theme) => ({
  root: {
    width: "100%",
    height: "100%",
  },
});

function PageWrapper({
  history,
  classes,
  context,
  dispatch,
  children,
  showHeader,
  showSidebar,
  title,
  showLoading,
  onInfoClick,
}) {
  const [showMinimizedSidebar, setShowMinimizedSidebar] = React.useState(false);
  const [
    updatePlayerDetailsError,
    setUpdatePlayerDetailsError,
  ] = React.useState(null);
  const [updatingPlayerDetails, setUpdatingPlayerDetails] = React.useState(
    false
  );
  const [playerInformation, setPlayerInformation] = React.useState({});
  const showSelectPlayer = context.showSelectPlayer;
  const [
    resendingVerificationEmail,
    setResendingVerificationEmail,
  ] = React.useState(false);
  const [submitAttempted, setSubmitAttempted] = React.useState(false);

  const selectPlayer = async (player, membership) => {
    const loginToClubData = await apiLoginToClub(membership.clubId, player.id);

    if (loginToClubData && loginToClubData.accessToken) {
      dispatch({
        type: "storeSessionVariables",
        currentClub: membership,
        currentPlayer: player,
        tokens: {
          accessToken: loginToClubData.accessToken,
          refreshToken: loginToClubData.refreshToken,
        },
      });
      closeSelectPlayer();
    }
    history.push("/");
  };

  const closeSelectPlayer = () => {
    dispatch({
      type: "closeSelectPlayer",
    });
  };

  const getProfile = async () => {
    try {
      const data = await apiGetProfile();

      if (data) {
        dispatch({
          type: "storeProfile",
          profile: data,
        });
      }
    } catch (e) {
      console.log(e);
    }
  };

  const resendVerificationEmail = async () => {
    try {
      setResendingVerificationEmail(true);
      await apiResendVerificationEmail(context.organizationData.guid);
      setResendingVerificationEmail(false);
    } catch (e) {
      console.log(e);
      setResendingVerificationEmail(false);
    }
  };

  const isInformationComplete = (requiredFields, playerDetails) => {
    if (requiredFields) {
      for (var field in requiredFields) {
        if (
          requiredFields[field] === 2 &&
          (!playerDetails ||
            !playerDetails[field] ||
            playerDetails[field].length === 0)
        ) {
          return false;
        }
      }
    }
    return true;
  };

  const isPrivacyPolicyAgreed = () => {
    if (
      context.currentClub.latestPrivacyPolicy &&
      (!context.currentPlayer.privacyConsentDate ||
        context.currentClub.latestPrivacyPolicy.dateCreated >
          context.currentPlayer.privacyConsentDate)
    ) {
      return false;
    }
    return true;
  };

  const updatePlayerDetails = async () => {
    setUpdatePlayerDetailsError(null);
    setSubmitAttempted(false);

    if (
      isInformationComplete(
        context.currentClub.playerInformationSettings,
        playerInformation
      )
    ) {
      if (
        playerInformation.agree ||
        !context.currentClub.latestPrivacyPolicy.privacyPolicy ||
        context.currentClub.latestPrivacyPolicy.privacyPolicy.length === 0
      ) {
        setUpdatingPlayerDetails(true);

        // if (this.state.homeAddress.length > 0) {
        //   playerInformation.homeAddress = this.state.homeAddress;
        // }

        try {
          await apiUpdatePlayerDetails(
            context.currentPlayer.id,
            playerInformation
          );
          const getProfileData = await apiGetProfile();
          dispatch({
            type: "storeProfile",
            profile: getProfileData,
          });

          const updatedCurrentPlayer = getProfileData.players.find(
            (player) => player.id === context.currentPlayer.id
          );

          dispatch({
            type: "storeCurrentPlayer",
            currentPlayer: updatedCurrentPlayer,
          });
        } catch (e) {
          setUpdatePlayerDetailsError(
            "An error occurred updating player details"
          );
        }
      } else {
        setUpdatePlayerDetailsError(
          "Please agree to the club's privacy policy"
        );
        setUpdatingPlayerDetails(false);
      }
    } else {
      setUpdatePlayerDetailsError("Please complete all required fields");
      setUpdatingPlayerDetails(false);
    }
  };

  const showCompleteData =
    context.currentClub &&
    context.currentPlayer &&
    context.currentClub.latestPrivacyPolicy &&
    context.currentClub.latestPrivacyPolicy.privacyPolicy &&
    context.currentClub.latestPrivacyPolicy.privacyPolicy.length > 0 &&
    (!isInformationComplete(
      context.currentClub.playerInformationSettings,
      context.currentPlayer.playerInformation
    ) ||
      !isPrivacyPolicyAgreed());

  const logout = () => {
    dispatch({
      type: "clearToken",
    });
    dispatch({
      type: "clearSessionVariables",
    });

    if (context.loggedInAsAdmin) {
      history.push("/select-player");
    } else {
      history.push("/");
    }
  };

  useEffect(() => {
    if (context.currentPlayer.playerInformation) {
      setPlayerInformation(context.currentPlayer.playerInformation);
    }
  }, [context.currentPlayer.playerInformation]);

  if (!context.loaded) {
    return <div />;
  }

  return (
    <div className={classes.root}>
      {showHeader && (
        <PageHeader
          setShowMinimizedSidebar={() =>
            setShowMinimizedSidebar(!showMinimizedSidebar)
          }
          showSidebar={showSidebar}
        />
      )}
      <PageContent
        history={history}
        showHeader={showHeader}
        showSidebar={showSidebar}
        title={title}
        showMinimizedSidebar={showMinimizedSidebar}
        showOnInfoClick={onInfoClick}
        onInfoClick={() => onInfoClick && onInfoClick()}
      >
        {showLoading ? (
          <div>
            <CircularProgress />
          </div>
        ) : (
          children
        )}
      </PageContent>

      {showSelectPlayer && (
        <DialogModal
          title="Select your player"
          onClose={() => closeSelectPlayer()}
        >
          <SelectPlayerForm
            players={(context.profile && context.profile.players) || []}
            onSelectPlayer={(player, membership) => {
              selectPlayer(player, membership);
            }}
            onClose={() => closeSelectPlayer()}
          />
        </DialogModal>
      )}

      {context.profile && context.profile.isEmailActivated && showCompleteData && (
        <DialogModal
          title="Please complete the following information"
          onSubmit={() => updatePlayerDetails()}
          submitText="Save"
          submitting={updatingPlayerDetails}
          cancelText="Logout"
          onCancel={() => logout()}
        >
          <RegistrationInfoForm
            requiredFields={context.currentClub.playerInformationSettings}
            latestPrivacyPolicy={context.currentClub.latestPrivacyPolicy}
            payload={playerInformation}
            storePayload={(payload) => setPlayerInformation(payload)}
            errorMessage={updatePlayerDetailsError}
            submitAttempted={submitAttempted}
          />
        </DialogModal>
      )}

      {context.profile &&
        context.profile.id &&
        !context.profile.isEmailActivated &&
        !window.location.pathname.startsWith("/verify-account") && (
          <DialogModal title="Please confirm your email address">
            <div style={{ textAlign: "center" }}>
              <div style={{ marginBottom: "20px" }}>
                Please check your mailbox and junk folder for your verification
                email from{" "}
                <a href="mailto:help@slateapp.co.uk">help@slateapp.co.uk</a>.
              </div>
              <div>
                <Button
                  style={{ marginBottom: 20 }}
                  title="I have verified my email address"
                  onClick={() => {
                    getProfile();
                  }}
                />
              </div>
              <div>
                <Button
                  style={{
                    backgroundColor: "rgb(243, 243, 243)",
                    color: "#222",
                    marginBottom: 10,
                  }}
                  title="Resend verification email"
                  onClick={() => resendVerificationEmail()}
                  submitting={resendingVerificationEmail}
                />
              </div>
            </div>
          </DialogModal>
        )}
    </div>
  );
}

export default withStyles(styles)(withState(PageWrapper));
