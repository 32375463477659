import React from "react";
import { withStyles } from "@material-ui/core/styles";

import { lookupPostcode as apiLookupPostcode } from "../../../services/api";

import SingleLineTextInput from "../../components/singleLineTextInput/SingleLineTextInput";
import MultiLineTextInput from "../../components/multiLineTextInput/MultiLineTextInput";
import DatePickerInput from "../../components/datePickerInput/DatePickerInput";
import DropdownSelect from "../../components/dropdownSelect/DropdownSelect";
import DialogModal from "../../components/dialogModal/DialogModal";
import Checkbox from "../../components/checkbox/Checkbox";
import ErrorMessage from "../../components/errorMessage/ErrorMessage";

const isInvalidName = (name) => !name;

const genderOptions = [
  { label: "Male", value: "male" },
  { label: "Female", value: "female" },
  { label: "Unspecified", value: "unspecified" },
];

const styles = (theme) => ({
  section: {
    width: "100%",
    backgroundColor: "#fff",
  },
});

function RegistrationInfoForm({
  classes,
  payload,
  storePayload,
  requiredFields,
  latestPrivacyPolicy,
  errorMessage,
  submitAttempted,
}) {
  const [showPrivacyPolicy, setShowPrivacyPolicy] = React.useState(false);
  const [lookingUpPostcode, setLookingUpPostcode] = React.useState(false);
  const [postcodeResults, setPostcodeResults] = React.useState([]);

  const lookupPostcode = async () => {
    try {
      setLookingUpPostcode(true);
      const results = await apiLookupPostcode({
        postcode: payload.postcodeLookup,
      });

      if (results && results.length > 0) {
        setPostcodeResults(results);
        storePayload({ ...payload, homeAddress: results[0] });
      }
      setLookingUpPostcode(false);
    } catch (e) {
      console.log(e);
      setLookingUpPostcode(false);
    }
  };

  let homeAddressOptions = [];

  if (postcodeResults.length > 0) {
    homeAddressOptions = postcodeResults.map((postcodeResult) => {
      return {
        value: postcodeResult,
        label: postcodeResult,
      };
    });
  } else if (payload.homeAddress) {
    homeAddressOptions = [
      {
        value: payload.homeAddress,
        label: payload.homeAddress,
      },
    ];
  }

  return (
    <div className={classes.section}>
      {(requiredFields["dateOfBirth"] === 1 ||
        requiredFields["dateOfBirth"] === 2) && (
        <DatePickerInput
          label="DATE OF BIRTH"
          value={payload.dateOfBirth}
          onChange={(value) => storePayload({ ...payload, dateOfBirth: value })}
          showInvalidInput={
            submitAttempted && isInvalidName(payload.dateOfBirth)
          }
          required={requiredFields["dateOfBirth"] === 2}
        />
      )}

      {(requiredFields["homeAddress"] === 1 ||
        requiredFields["homeAddress"] === 2) && (
        <div>
          <SingleLineTextInput
            label="POSTCODE"
            value={payload.postcodeLookup}
            onChange={(value) =>
              storePayload({ ...payload, postcodeLookup: value })
            }
            button="search"
            onButtonClick={() => lookupPostcode()}
            buttonSubmitting={lookingUpPostcode}
          />
          <DropdownSelect
            label="ADDRESS"
            value={payload.homeAddress || ""}
            items={homeAddressOptions}
            onSelect={(value) =>
              storePayload({ ...payload, homeAddress: value })
            }
            required={requiredFields["homeAddress"] === 2}
          />
        </div>
      )}

      {(requiredFields["gender"] === 1 || requiredFields["gender"] === 2) && (
        <DropdownSelect
          label="GENDER"
          value={payload.gender}
          items={genderOptions}
          onSelect={(itemId) => storePayload({ ...payload, gender: itemId })}
          required={requiredFields["gender"] === 2}
        />
      )}

      {(requiredFields["phoneNumber"] === 1 ||
        requiredFields["phoneNumber"] === 2) && (
        <SingleLineTextInput
          label="PHONE NUMBER"
          value={payload.phoneNumber}
          onChange={(value) => storePayload({ ...payload, phoneNumber: value })}
          showInvalidInput={
            submitAttempted && isInvalidName(payload.phoneNumber)
          }
          required={requiredFields["phoneNumber"] === 2}
        />
      )}

      {(requiredFields["medicalConditions"] === 1 ||
        requiredFields["medicalConditions"] === 2) && (
        <MultiLineTextInput
          label="MEDICAL CONDITIONS"
          value={payload.medicalConditions}
          onChange={(value) =>
            storePayload({ ...payload, medicalConditions: value })
          }
          showInvalidInput={
            submitAttempted && isInvalidName(payload.medicalConditions)
          }
          required={requiredFields["medicalConditions"] === 2}
        />
      )}

      {(requiredFields["emergencyContactName"] === 1 ||
        requiredFields["emergencyContactName"] === 2) && (
        <SingleLineTextInput
          label="EMERGENCY CONTACT NAME"
          value={payload.emergencyContactName}
          onChange={(value) =>
            storePayload({ ...payload, emergencyContactName: value })
          }
          showInvalidInput={
            submitAttempted && isInvalidName(payload.emergencyContactName)
          }
          required={requiredFields["emergencyContactName"] === 2}
        />
      )}

      {(requiredFields["emergencyContactNumber"] === 1 ||
        requiredFields["emergencyContactNumber"] === 2) && (
        <SingleLineTextInput
          label="EMERGENCY CONTACT NUMBER"
          value={payload.emergencyContactNumber}
          onChange={(value) =>
            storePayload({ ...payload, emergencyContactNumber: value })
          }
          showInvalidInput={submitAttempted && isInvalidName(payload.name)}
          required={requiredFields["emergencyContactNumber"] === 2}
        />
      )}

      {(latestPrivacyPolicy && latestPrivacyPolicy.privacyPolicy.length) >
        0 && (
        <Checkbox
          label={
            <div>
              I agree to the club's{" "}
              <span
                style={{ textDecorationLine: "underline", cursor: "pointer" }}
                onClick={() => setShowPrivacyPolicy(true)}
              >
                privacy policy
              </span>
            </div>
          }
          value={payload.agree}
          onClick={() => storePayload({ ...payload, agree: !payload.agree })}
        />
      )}

      {errorMessage && <ErrorMessage message={errorMessage} />}

      {showPrivacyPolicy && (
        <DialogModal
          onClose={() => setShowPrivacyPolicy(false)}
          cancelText="Close"
        >
          <div>{latestPrivacyPolicy.privacyPolicy}</div>
        </DialogModal>
      )}
    </div>
  );
}

export default withStyles(styles)(RegistrationInfoForm);
