import React from "react";
import { withStyles } from "@material-ui/core/styles";
import moment from "moment";

import SingleLineTextInput from "../../components/singleLineTextInput/SingleLineTextInput";
import DatePickerInput from "../../components/datePickerInput/DatePickerInput";
import TimePickerInput from "../../components/timePickerInput/TimePickerInput";
import DropdownSelect from "../../components/dropdownSelect/DropdownSelect";
import Button from "../../components/button/Button";

const isInvalidName = (name) => !name;

const styles = (theme) => ({
  section: {
    width: "100%",
    backgroundColor: "#fff",
  },
});

function AddEventForm({
  classes,
  payload,
  storePayload,
  onSubmit,
  errorMessage,
  submitting,
  teams,
  eventTypes,
}) {
  const [submitAttempted, setSubmitAttempted] = React.useState(false);

  return (
    <div className={classes.section}>
      <SingleLineTextInput
        label="NAME"
        value={payload.name}
        onChange={(value) => storePayload({ ...payload, name: value })}
        showInvalidInput={submitAttempted && isInvalidName(payload.name)}
      />
      <SingleLineTextInput
        label="LOCATION"
        value={payload.eventLocation}
        onChange={(value) => storePayload({ ...payload, eventLocation: value })}
      />
      <DatePickerInput
        label="EVENT DATE"
        value={payload.eventDate || moment()}
        onChange={(value) =>
          storePayload({
            ...payload,
            eventDate: moment.utc(value).startOf("day"),
          })
        }
        showInvalidInput={submitAttempted && isInvalidName(payload.eventDate)}
      />
      <TimePickerInput
        label="EVENT START TIME"
        value={moment.utc(payload.eventStartTime, "HH:mm") || moment()}
        onChange={(value) => {
          storePayload({
            ...payload,
            eventStartTime: moment.utc(value).format("HH:mm"),
            eventEndTime: moment.utc(value).add(1, "hour").format("HH:mm"),
          });
        }}
        showInvalidInput={false}
      />
      <TimePickerInput
        label="EVENT END TIME"
        value={moment.utc(payload.eventEndTime, "HH:mm") || moment()}
        onChange={(value) => {
          storePayload({
            ...payload,
            eventEndTime: moment.utc(value).format("HH:mm"),
          });
        }}
        showInvalidInput={false}
      />
      <DropdownSelect
        label="TEAM"
        value={payload.teamId}
        items={teams.map((team) => {
          return { value: team.id, label: team.name };
        })}
        onSelect={(itemId) => storePayload({ ...payload, teamId: itemId })}
      />
      <DropdownSelect
        label="EVENT TYPE"
        value={payload.eventTypeId}
        items={eventTypes.map((eventType) => {
          return { value: eventType.id, label: eventType.name };
        })}
        onSelect={(itemId) => storePayload({ ...payload, eventTypeId: itemId })}
      />
      {onSubmit && (
        <Button
          title="Next"
          onClick={() => {
            setSubmitAttempted(true);
            onSubmit.utc(payload);
          }}
          submitting={submitting}
        />
      )}
    </div>
  );
}

export default withStyles(styles)(AddEventForm);
