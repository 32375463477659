import React, { useEffect, useCallback } from "react";
import { withStyles } from "@material-ui/core/styles";

import { withState } from "../../../services/state";
import { getClubPlayers as apiGetClubPlayers } from "../../../services/api";

import PageWrapper from "../../components/pageWrapper/PageWrapper";
import Section from "../../components/section/Section";
import DialogModal from "../../components/dialogModal/DialogModal";

import OutstandingFeesTable from "../../tables/outstandingFeesTable/OutstandingFeesTable";
import ViewOutstandingFeesTable from "../../tables/viewOutstandingFeesTable/ViewOutstandingFeesTable";

const styles = (theme) => ({
  root: {
    width: "100%",
  },
});

function OutstandingFeesPage({ history, context }) {
  //   const [gettingOutstandingFees, setGettingOutstandingFess] = React.useState(false);
  const [oustandingFees, setOutstandingFees] = React.useState([]);
  const [viewPlayerFees, setViewPlayerFees] = React.useState(null);

  const getOutstandingFees = useCallback(async () => {
    if (!context.currentPlayer || !context.currentPlayer.id) {
      return false;
    }

    try {
      const data = await apiGetClubPlayers(context.currentClub.clubId);

      if (data && !data.reason) {
        var outstandingFees = data
          .map((player) => {
            let playerName = `${player.firstName} ${player.lastName}`;
            let totalOutstandingFee =
              player.overdueEventFees && player.overdueEventFees.length > 0
                ? player.overdueEventFees
                    .map((event) => {
                      return event.currentFee;
                    })
                    .reduce((totalOutstanding, eventFee) => {
                      return totalOutstanding + eventFee;
                    })
                : 0;

            return {
              playerName,
              totalOutstandingFee,
              overdueEventFees:
                player.overdueEventFees &&
                player.overdueEventFees.map((outstandingFee) => {
                  return {
                    eventName: outstandingFee.event.name,
                    date: outstandingFee.event.eventDate,
                    teamName: outstandingFee.event.team.name,
                    eventFee: outstandingFee.currentFee,
                  };
                }),
            };
          })
          .filter((eventFee) => eventFee.totalOutstandingFee > 0)
          .sort((a, b) =>
            a.totalOutstandingFee > b.totalOutstandingFee ? -1 : 1
          );

        setOutstandingFees(outstandingFees);
      }
    } catch (e) {
      console.log(e);
    }
  }, [context.currentClub, context.currentPlayer]);

  useEffect(() => {
    getOutstandingFees();
  }, [getOutstandingFees]);

  return (
    <PageWrapper
      history={history}
      showHeader={true}
      showSidebar={true}
      title="Report"
    >
      <Section>
        <OutstandingFeesTable
          data={oustandingFees}
          onViewPlayerFees={(player) => setViewPlayerFees(player)}
        />
      </Section>

      {viewPlayerFees && (
        <DialogModal
          title={viewPlayerFees.playerName}
          onClose={() => setViewPlayerFees(null)}
          cancelText="Close"
        >
          <ViewOutstandingFeesTable
            data={viewPlayerFees.overdueEventFees}
            onClose={() => setViewPlayerFees(null)}
            hideSearch={true}
          />
        </DialogModal>
      )}
    </PageWrapper>
  );
}

export default withStyles(styles)(withState(OutstandingFeesPage));
