import React from "react";
import { withStyles } from "@material-ui/core/styles";

import { withState } from "../../../services/state";

import Button from "../../components/button/Button";

import AddPlayerForm from "../addPlayerForm/AddPlayerForm";

const styles = (theme) => ({
  section: {
    width: "100%",
    backgroundColor: "#fff",
  },
  playersList: {
    marginBottom: 20,
  },
  selectPlayer: {
    padding: "10px",
    cursor: "pointer",
    "&:not(:last-child)": {
      borderBottom: "1px solid #ececec",
    },
  },
  modalButtons: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    marginTop: 20,
  },
});

function SelectPlayerForm({
  classes,
  players,
  onSelectPlayer,
  onCreatePlayer,
  onLogout,
  context,
}) {
  const [showCreatePlayer, setShowCreatePlayer] = React.useState(false);
  const [addMember, setAddMember] = React.useState({});

  const renderContent = () => {
    if (showCreatePlayer) {
      return (
        <AddPlayerForm
          payload={addMember}
          storePayload={(payload) => setAddMember(payload)}
          onSubmit={(payload) => {
            onCreatePlayer(payload);
            setShowCreatePlayer(false);
          }}
          onBack={() => setShowCreatePlayer(false)}
        />
      );
    }

    return (
      <div>
        <div className={classes.playersList}>
          {players.map((player) => {
            return player.memberships
              .filter(
                (membership) =>
                  membership.enabled &&
                  membership.organizationId === context.organizationData.guid
              )
              .map((membership) => {
                return (
                  <div
                    className={classes.selectPlayer}
                    onClick={() => onSelectPlayer(player, membership)}
                  >
                    <div>
                      {player.firstName} {player.lastName}
                    </div>
                    <div>{membership.name}</div>
                  </div>
                );
              });
          })}
        </div>

        <div className={classes.modalButtons}>
          <div>
            {onLogout && (
              <Button
                title="Logout"
                onClick={() => {
                  onLogout();
                }}
                style={{ backgroundColor: "rgb(243, 243, 243)", color: "#222" }}
              />
            )}
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className={classes.section}>
      <div>
        {renderContent({
          classes,
          players,
          showCreatePlayer,
          setShowCreatePlayer,
          onSelectPlayer,
          onCreatePlayer,
        })}
      </div>
    </div>
  );
}

export default withStyles(styles)(withState(SelectPlayerForm));
