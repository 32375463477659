import React from "react";

import { withState } from "../../../services/state";
import { requestPasswordReset as apiRequestPasswordReset } from "../../../services/api";

import PageWrapper from "../../components/pageWrapper/PageWrapper";
import AuthenticationFlow from "../../components/authenticationFlow/AuthenticationFlow";

import RequestPasswordResetForm from "../../forms/requestPasswordResetForm/RequestPasswordResetForm";

const ForgotPasswordPage = ({ history, context }) => {
  const [currentStep, setCurrentStep] = React.useState(0);
  const [requestPasswordResetting, setRequestingNewPassword] = React.useState(
    false
  );
  const [requestPasswordResetError, setForgotPasswordError] = React.useState(
    ""
  );
  const [
    requestPasswordResetMessage,
    setForgotPasswordMessage,
  ] = React.useState("");

  const requestPasswordReset = async (payload, callback) => {
    if (!payload.emailAddress) {
      return setForgotPasswordError("Please enter an email address");
    }

    setForgotPasswordError("");

    try {
      setRequestingNewPassword(true);
      const requestPasswordResetData = await apiRequestPasswordReset({
        ...payload,
        organizationId: context.organizationData.guid,
      });
      if (requestPasswordResetData.errors) {
        setForgotPasswordError(requestPasswordResetData.errors[0].reason);
      } else {
        setForgotPasswordMessage("An email has been sent");
        callback();
      }
      setRequestingNewPassword(false);
    } catch (e) {
      setRequestingNewPassword(false);
      setForgotPasswordError(e.message);
    }
  };

  return (
    <PageWrapper history={history} showHeader={true}>
      <AuthenticationFlow
        currentStep={currentStep}
        onGoBack={() => setCurrentStep(currentStep - 1)}
        steps={[
          <RequestPasswordResetForm
            onSubmit={(payload, callback) => {
              requestPasswordReset(payload, callback);
            }}
            submitting={requestPasswordResetting}
            error={requestPasswordResetError}
            message={requestPasswordResetMessage}
          />,
        ]}
      />
    </PageWrapper>
  );
};

export default withState(ForgotPasswordPage);
