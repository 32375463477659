import customError from "../util/customError";

/* ----------- EXCEPTIONS ---------- */
export const UnableToConnectException = customError(
  "UnableToConnectException",
  "Unable to connect to the server."
);
export const ServerErrorException = customError(
  "ServerErrorException",
  "There was an error on the server."
);
export const NotFoundException = customError(
  "NotFoundException",
  "Endpoint not found."
);
export const AuthenticationException = customError(
  "AuthenticationException",
  "User is not authenticated."
);
export const BadRequestException = customError(
  "BadRequestException",
  "Bad request sent."
);
export const TimeoutException = customError(
  "TimeoutException",
  "The request timed out."
);

/* ----------- PRIVATE FUNCTIONS ---------- */
const ADDRESS = process.env.REACT_APP_API_URL;

function convertError(data) {
  if (data.errors) {
    if (Array.isArray(data.errors)) {
      return data.errors[0].reason;
    } else if (typeof data.errors === "object") {
      const firstKey = Object.keys(data.errors)[0];
      return data.errors[firstKey];
    }
  }

  return "An error occurred";
}

function getAuthHeader() {
  const existingTokensStr = localStorage.getItem("slate-player-web-app-tokens");

  if (existingTokensStr) {
    const existingTokens = JSON.parse(existingTokensStr);
    return "Bearer " + existingTokens.accessToken;
  }
}

function getAdminAuthHeader() {
  const existingTokensStr = localStorage.getItem(
    "slate-player-web-app-admin-tokens"
  );

  if (existingTokensStr) {
    const existingTokens = JSON.parse(existingTokensStr);
    return "Bearer " + existingTokens.accessToken;
  }
}

async function request(path, settings) {
  let response;
  try {
    response = await fetch(`${ADDRESS}${path}`, settings);
  } catch (e) {
    console.log(e);
    throw new UnableToConnectException();
  }

  if (response.status === 404) throw new NotFoundException();
  if (response.status === 500) throw new ServerErrorException();
  if (response.status === 401) throw new AuthenticationException();
  if (response.status === 403) throw new AuthenticationException();
  if (response.status === 400)
    throw new BadRequestException(convertError(await response.json()));

  return response;
}

export async function get(path, body) {
  return await request(path, {
    headers: {
      Authorization: getAuthHeader(),
    },
    body: JSON.stringify(body),
  });
}

export async function del(path, body) {
  return await request(path, {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
      Authorization: getAuthHeader(),
    },
    body: JSON.stringify(body),
  });
}

export async function post(path, body) {
  return await request(path, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: getAuthHeader(),
    },
    body: JSON.stringify(body),
  });
}

export async function put(path, body) {
  return await request(path, {
    method: "PUT",
    headers: {
      Accept: "application/json, text/plain, */*",
      "Content-Type": "application/json",
      Authorization: getAuthHeader(),
    },
    body: JSON.stringify(body),
  });
}

// export async function patch(path, body) {
//   return await request(path, {
//     method: "PUT",
//     headers: {
//       "Content-Type": "application/json",
//       Authorization: getAuthHeader()
//     },
//     body: JSON.stringify(body)
//   });
// }

export async function postFile(path, file) {
  const formData = new FormData();
  formData.append("file", file);

  return await request(path, {
    method: "POST",
    headers: {
      Accept: "application/json, text/plain, */*",
      Authorization: getAuthHeader(),
    },
    body: formData,
  });
}

export async function uploadImageToUrl(uploadUrl, image) {
  console.log(image.type);
  console.log(uploadUrl);
  var xhr = new XMLHttpRequest();
  // xhr.upload.onprogress = (evt) => {
  //   if (evt.lengthComputable) {
  //     var percentComplete = parseInt((evt.loaded / evt.total) * 100, 10);
  //   }
  // };
  xhr.open("PUT", uploadUrl);
  xhr.setRequestHeader("Content-Type", image.type);
  xhr.onprogress = (e) => {
    if (e.lengthComputable) {
      console.log(e.loaded + " / " + e.total);
    }
  };
  xhr.onloadstart = (e) => {
    console.log("start");
  };
  xhr.onloadend = (e) => {
    return true;
  };
  xhr.send(image);
}

/* ----------- PUBLIC FUNCTIONS ----------- */
export async function login({ emailAddress, password }) {
  const response = await post("/user/login", {
    emailAddress: emailAddress.toLowerCase().trim(),
    password,
  });
  return await response.json();
}

export async function adminLogin({ emailAddress, password }) {
  const response = await post("/admin/login", {
    emailAddress: emailAddress.toLowerCase().trim(),
    password,
  });
  return await response.json();
}

export async function adminLoginAsUser({ emailAddress }) {
  const response = await request("/admin/login-as-user", {
    method: "POST",
    headers: {
      Authorization: getAdminAuthHeader(),
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      emailAddress: emailAddress.toLowerCase().trim(),
    }),
  });
  return await response.json();
}

export async function register({ emailAddress, password, organizationId }) {
  const response = await post("/user/player-register", {
    EmailAddress: emailAddress.toLowerCase().trim(),
    Password: password,
    OrganizationId: organizationId,
  });
  return await response.json();
}

export async function requestPasswordReset({ emailAddress, organizationId }) {
  const response = await post("/user/password/request-reset", {
    EmailAddress: emailAddress.toLowerCase().trim(),
    OrganizationId: organizationId,
  });
  return await response;
}

export async function resetPassword({ code, emailAddress, password }) {
  const response = await post(`/user/password/reset/${code}`, {
    EmailAddress: emailAddress.toLowerCase().trim(),
    Password: password,
  });
  return await response.json();
}

export async function acceptInvite({
  code,
  emailAddress,
  password,
  signUpToNewsletter,
}) {
  const response = await post(`/user/accept-invite/${code}`, {
    EmailAddress: emailAddress.toLowerCase().trim(),
    Password: password,
    SignUpToNewsletter: signUpToNewsletter,
  });
  return await response.json();
}

export async function verifyAccount({ code }) {
  const response = await post(`/user/verify-account/${code}`);
  return response.json();
}

export async function resendVerificationEmail(organizationId) {
  const response = await post("/user/resend_verification_email", {
    OrganizationId: organizationId,
  });
  return response;
}

export async function refreshToken(refreshToken) {
  const response = await post("/auth/refresh", {
    RefreshToken: refreshToken,
  });
  return await response.json();
}

export async function getOrganizationData(organizationSlug) {
  const response = await get(`/organization/${organizationSlug}`);
  return await response.json();
}

export async function getProfile() {
  const response = await get("/user/me");
  return await response.json();
}

export async function lookupPostcode({ postcode }) {
  const response = await post("/user/lookup_postcode", {
    Postcode: postcode,
  });
  return await response.json();
}

export async function updatePlayerDetails(playerId, payload) {
  const response = await put(`/player/${playerId}/player-information`, {
    HomeAddress: payload.homeAddress,
    DateOfBirth: payload.dateOfBirth,
    MedicalConditions: payload.medicalConditions,
    PhoneNumber: payload.phoneNumber,
    EmergencyContactName: payload.emergencyContactName,
    EmergencyContactNumber: payload.emergencyContactNumber,
    Gender: payload.gender,
  });
  return await response.json();
}

export async function loginToClub(clubId, playerId) {
  const response = await post(`/club/${clubId}/login`, {
    playerId,
  });
  return await response.json();
}

export async function logoutOfClub(clubId) {
  const response = await post(`/club/${clubId}/logout`);
  return await response.json();
}

export async function createPlayer({ firstName, lastName }) {
  const response = await post("/player", {
    FirstName: firstName,
    LastName: lastName,
    MarketingOptIn: true,
  });
  return await response.json();
}

export async function uploadSpreadsheet(clubId, file) {
  const response = await postFile(`/club/${clubId}/upload_spreadsheet`, file);
  return await response.json();
}

export async function getClubs() {
  const response = await get("/user/me");
  return await response.json();
}

export async function getClub(clubId) {
  const response = await get(`/club/${clubId}`);
  return await response.json();
}

export async function getClubPlayers(clubId) {
  const response = await get(`/club/${clubId}/members`);
  return await response.json();
}

export async function updateClub(clubId, payload) {
  const response = await put(`/club/${clubId}`, {
    Name: payload.name,
    Sport: payload.sport,
    Address: {
      AddressLineOne: payload.address.addressLineOne,
      AddressLineTwo: payload.address.addressLineTwo,
      Town: payload.address.town,
      County: payload.address.county,
      Postcode: payload.address.postcode,
    },
  });
  return await response.json();
}

export async function getLogoUploadUrl() {
  const response = await get(`/club/get_logo_upload_url`);
  return await response.text();
}

export async function uploadLogo(uploadUrl, image) {
  await uploadImageToUrl(uploadUrl, image);
  return true;
}

export async function updatePlayerInformation(clubId, payload) {
  const response = await put(`/club/${clubId}/player-information`, {
    HomeAddress: payload.homeAddress,
    DateOfBirth: payload.dateOfBirth,
    MedicalConditions: payload.medicalConditions,
    PhoneNumber: payload.phoneNumber,
    EmergencyContactName: payload.emergencyContactName,
    EmergencyContactNumber: payload.emergencyContactNumber,
  });
  return await response.json();
}

export async function updatePrivacyPolicy(clubId, payload) {
  const response = await post(`/club/${clubId}/privacy-policy`, {
    PrivacyPolicy: payload,
  });
  return await response.json();
}

export async function createClub(payload, playerId) {
  const response = await post("/club", {
    Name: payload.name,
    Sport: payload.sport,
    Address: {
      AddressLineOne: payload.address.addressLineOne,
      AddressLineTwo: payload.address.addressLineTwo,
      Town: payload.address.town,
      County: payload.address.county,
      Postcode: payload.address.postcode,
    },
    PlayerId: playerId,
  });
  return await response.json();
}

export async function createGoCardlessMerchant(clubId) {
  const response = await get(`/club/${clubId}/merchant/create`);
  return await response.json();
}

export async function authorizeGoCardlessMerchant(
  code,
  state,
  error,
  errorDescription
) {
  const response = await get(
    `/club/merchant/authorize?code=${code}&state=${state}&error=${
      error || ""
    }&error_description=${errorDescription || ""}`
  );
  return await response.json();
}

export async function getDetailedPaymentReport(
  clubId,
  startDate,
  endDate,
  filterPayload
) {
  console.log(filterPayload);
  const response = await get(
    `/club/${clubId}/detailed-payment-report?startDate=${startDate}&endDate=${endDate}${Object.keys(
      filterPayload
    )
      .filter((key) => filterPayload[key])
      .map((key) => `&${key}=${filterPayload[key]}`)
      .join("")}`
  );
  return await response.json();
}

export async function getSummaryPaymentReport(
  clubId,
  startDate,
  endDate,
  filterPayload
) {
  console.log(filterPayload);
  const response = await get(
    `/club/${clubId}/summary-payment-report?startDate=${startDate}&endDate=${endDate}${Object.keys(
      filterPayload
    )
      .filter((key) => filterPayload[key])
      .map((key) => `&${key}=${filterPayload[key]}`)
      .join("")}`
  );
  return await response.json();
}

export async function getEvents() {
  const response = await get(`/event`);
  return await response.json();
}

export async function getEvent(eventId) {
  const response = await get(`/event/${eventId}`);
  return await response.json();
}

export async function createEvent(payload) {
  const response = await post(`/event`, {
    Name: payload.name,
    EventLocation: payload.eventLocation,
    EventDate: payload.eventDate,
    eventStartTime: payload.eventStartTime,
    eventEndTime: payload.eventEndTime,
    TeamId: payload.teamId,
    EventTypeId: payload.eventTypeId,
  });
  return await response.json();
}

export async function updateEvent(eventId, payload) {
  const response = await put(`/event/${eventId}`, {
    Name: payload.name,
    EventLocation: payload.eventLocation,
    EventDate: payload.eventDate,
    eventStartTime: payload.eventStartTime,
    eventEndTime: payload.eventEndTime,
    TeamId: payload.teamId,
    EventTypeId: payload.eventTypeId,
    IsConfirmed: payload.isConfirmed,
  });
  return await response.json();
}
export async function archiveEvent(eventId) {
  const response = await post(`/event/${eventId}/archive`);
  return response;
}

export async function deleteEvent(eventId) {
  const response = await del(`/event/${eventId}`);
  return await response.json();
}

export async function getFeeCategories(clubId) {
  const response = await get(`/club/${clubId}/fee-categories`);
  return await response.json();
}

export async function getEventTypes(clubId) {
  const response = await get(`/club/${clubId}/event-types`);
  return await response.json();
}

export async function getTeams(clubId) {
  const response = await get(`/club/${clubId}/teams`);
  return await response.json();
}

export async function getCaptains(clubId, teamId) {
  const response = await get(`/club/${clubId}/teams/${teamId}/captains`);
  return await response.json();
}

export async function createCaptain(clubId, teamId, targetPlayerId) {
  const response = await post(`/club/${clubId}/teams/${teamId}/captains`, {
    TargetPlayerId: targetPlayerId,
  });
  return await response.json();
}

export async function deleteCaptain(clubId, teamId, captainId) {
  const response = await del(
    `/club/${clubId}/teams/${teamId}/captains/${captainId}`
  );
  return await response.json();
}

export async function getMembers(clubId) {
  const response = await get(`/club/${clubId}/members`);
  return await response.json();
}

export async function createMember(clubId, payload) {
  const response = await post(`/club/${clubId}/members/`, payload);
  return await response.json();
}

export async function updateMember(clubId, payload) {
  const response = await put(
    `/club/${clubId}/update-player/${payload.playerId}`,
    {
      FirstName: payload.firstName,
      LastName: payload.lastName,
    }
  );
  return await response.json();
}

export async function updateMemberFeeCategory(
  clubId,
  membershipId,
  feeCategoryId
) {
  const response = await put(
    `/club/${clubId}/update-club-membership/${membershipId}`,
    {
      FeeCategoryId: feeCategoryId,
    }
  );
  return await response.json();
}

export async function updateUserEmail(clubId, payload) {
  const response = await put(
    `/club/${clubId}/update-user/${payload.playerId}`,
    {
      EmailAddress: payload.emailAddress.toLowerCase().trim(),
    }
  );
  return await response.json();
}

export async function resendInvitationEmail(clubId, payload) {
  const response = await post(
    `/club/${clubId}/resend-player-invitation/${payload.playerId}`
  );
  return await response.json();
}

export async function deleteMember(clubId, memberId) {
  const response = await del(`/club/${clubId}/members/${memberId}`);
  return await response.json();
}

export async function getTeamCaptains(clubId, teamId) {
  const response = await get(`/club/${clubId}/teams/${teamId}/captains`);
  return await response.json();
}

export async function addTeamCaptain(clubId, teamId, targetPlayerId) {
  const response = await post(`/club/${clubId}/teams/${teamId}/captains`, {
    TargetPlayerId: targetPlayerId,
  });
  return await response.json();
}

export async function deleteTeamCaptain(clubId, teamId, captainId) {
  const response = await del(
    `/club/${clubId}/teams/${teamId}/captains/${captainId}`
  );
  return await response.json();
}

export async function addPlayerToEvent(eventId, targetPlayerId) {
  const response = await post(`/event/${eventId}/players`, {
    TargetPlayerId: targetPlayerId,
  });
  return await response.json();
}

export async function removePlayerFromEvent(eventId, playerId) {
  const response = await del(`/event/${eventId}/players/${playerId}`);
  return await response.json();
}

export async function getPlayerClubPaymentStatus(playerId, clubId) {
  const response = await get(
    `/player/${playerId}/club/${clubId}/payment_status`
  );
  return await response.json();
}

export async function completeDurectDebitSetup(redirectFlowId) {
  const response = await post(`/user/complete_direct_debit_setup`, {
    RedirectFlowId: redirectFlowId,
  });
  return await response.json();
}

export async function selectLastTeamForEvent(eventId) {
  const response = await post(`/event/${eventId}/last_team`);
  return response;
}

export async function updatePlayerEventFee(eventId, targetPlayerId, eventFee) {
  const response = await put(`/event/${eventId}/players/${targetPlayerId}`, {
    CurrentFee: eventFee,
  });
  return await response.json();
}

export async function submitPayment({ eventId, password, targetPlayerId }) {
  const response = await post(
    `/event/${eventId}/players/${targetPlayerId}/pay`,
    {
      Password: password,
    }
  );
  return await response.json();
}
