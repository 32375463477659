import React from "react";
import { withStyles } from "@material-ui/core/styles";
import { KeyboardDatePicker } from "@material-ui/pickers";
import moment from "moment";

import Label from "../label/Label";

const styles = (theme) => ({
  datePickerInput: {
    marginBottom: "10px",
  },
  input: {
    width: "100%",
    border: "1px solid #D7D7D7",
    borderRadius: "3px",
    outline: "none",
    paddingLeft: "10px",
    paddingTop: "10px",
    paddingBottom: "10px",
    fontSize: "18px",
  },
  invalidInput: {
    border: "1px solid red",
  },
});

function DatePickerInput({
  classes,
  style,
  value,
  label,
  type,
  showInvalidInput,
  onChange,
  required,
}) {
  // let inputClassName = classes.input;

  // if (showInvalidInput) {
  //   inputClassName += ` ${classes.invalidInput}`;
  // }

  return (
    <div className={classes.datePickerInput} style={style || {}}>
      {label && <Label title={label} required={required} />}
      <KeyboardDatePicker
        value={moment.utc(value)}
        onChange={(newValue) => {
          console.log(newValue);
          onChange(moment.utc(newValue));
        }}
        variant="inline"
        format="DD/MM/YYYY"
        disableToolbar={true}
        inputVariant="outlined"
        style={{ marginRight: 10 }}
      />
    </div>
  );
}

export default withStyles(styles)(DatePickerInput);
