import React from "react";
import { Link } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";
import moment from "moment";

const styles = (theme) => ({
  event: {
    width: "100%",
    backgroundColor: "#fff",
    padding: 15,
    borderBottom: "1px solid #e6e5e5",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  title: {
    color: "#000000",
    fontSize: "18px",
    lineHeight: "21px",
  },
});

function Event({ classes, event, actionButton, to, borderColor }) {
  return (
    <div
      className={classes.event}
      style={{ borderLeft: `5px solid ${borderColor}` }}
    >
      <Link to={to}>
        <div>
          {event.name && <div className={classes.title}>{event.name}</div>}
          {event.eventDate && (
            <div className={classes.title}>
              {moment.utc(event.eventDate).format("Do MMMM YYYY")}
            </div>
          )}
        </div>
      </Link>
      {actionButton}
    </div>
  );
}

export default withStyles(styles)(Event);
