import React, { useEffect, useCallback } from "react";
import { Link } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";
import Info from "@material-ui/icons/Info";

import { withState } from "../../../services/state";
import {
  getEvents as apiGetEvents,
  createEvent as apiCreateEvent,
  deleteEvent as apiDeleteEvent,
  archiveEvent as apiArchiveEvent,
  updateEvent as apiUpdateEvent,
  getTeams as apiGetTeams,
  getEventTypes as apiGetEventTypes,
  getPlayerClubPaymentStatus as apiGetPlayerClubPaymentStatus,
  submitPayment as apiSubmitPayment,
} from "../../../services/api";

import PageWrapper from "../../components/pageWrapper/PageWrapper";
import DialogModal from "../../components/dialogModal/DialogModal";
import YesNoDialogModal from "../../components/yesNoDialogModal/YesNoDialogModal";
import Section from "../../components/section/Section";
import Event from "../../components/event/Event";
import Button from "../../components/button/Button";

import AddEventForm from "../../forms/addEventForm/AddEventForm";
import EditEventForm from "../../forms/editEventForm/EditEventForm";
import PayForEventForm from "../../forms/payForEventForm/PayForEventForm";
import moment from "moment";

const styles = (theme) => ({
  root: {
    width: "100%",
  },
  eventHeader: {
    backgroundColor: "#c13535",
    color: "#fff",
    padding: 15,
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
  },
  infoClick: {
    marginLeft: 5,
    cursor: "pointer",
  },
  eventsButtons: {
    padding: 20,
    textAlign: "right",
  },
});

const getPlayerType = (currentClub) => {
  if (currentClub.isTreasurer) return "treasurer";
  if (currentClub.isCaptain) return "captain";
  return "player";
};

const event_info = {
  player:
    "This is a list of all team events you've been added to and need to pay for. To pay for an unpaid event, simply tap an event to view full team details.",
  treasurer:
    'This is a list of all team events that have been set-up for your Club. You can add new events at any time by clicking "CREATE" or tap an event to view full team details. If you are also a player in one of your teams, you can pay for an unpaid event by sliding left on the event and click the "PAY" button.',
  captain:
    'This is a list of all team events you\'ve been added to and need to pay for. To pay for an unpaid event, simply tap an event to view full team details. If you have been selected as a Manager for your team, you can also see who else on the teamsheet has paid, and have the option to pay on behalf of another player. You can also add new events by clicking "CREATE".',
};

const event_created = {
  captain:
    "Unconfirmed events are events you haven't yet finalised. Once you confirm these, players will be notified and you can start receiving payments. Please note you won't be able to make changes to an event once confirmed so please ensure that all the details are correct.",
  treasurer:
    "Unconfirmed events are events you haven't yet finalised. Once you confirm these, players will be notified and you can start receiving payments. Please note you won't be able to make changes to an event once confirmed so please ensure that all the details are correct.",
};

const event_unpaid = {
  treasurer:
    "Unpaid events are those which some team members have not yet paid for. Click an event to view a list of who's paid and who hasn't. You may remove events for which no one has yet paid (for example, if you need to change the date.)",
  captain:
    "Unpaid events are those which some team members have not yet paid for. Click an event to view a list of who's paid and who hasn't. You may remove events for which no one has yet paid (for example, if you need to change the date.)",
};

const event_paid = {
  treasurer: "Paid events are those for which all team members have now paid.",
  captain: "Paid events are those for which all team members have now paid.",
};

const no_events = {
  treasurer:
    "There are no events scheduled at the moment. You can create an event by clicking on the CREATE button above.",
  captain:
    "There are no events scheduled at the moment. You can create an event by clicking on the CREATE button above.",
  player:
    "There are no events scheduled at the moment. We will send you an email when you have been selected for an event you need to pay for.",
};

function EventsPage({ history, classes, dispatch, context }) {
  const [showAddEvent, toggleShowAddEvent] = React.useState(false);
  const [newEvent, storeNewEvent] = React.useState(false);
  const [gettingEvents, setGettingEvents] = React.useState(false);
  const [events, setEvents] = React.useState([]);
  const [creatingEvent, setCreatingEvent] = React.useState(true);
  const [createEventError, setCreateEventError] = React.useState(true);
  const [editEvent, setEditEvent] = React.useState(null);
  const [updatingEvent, setUpdatingEvent] = React.useState(true);
  const [updateEventError, setUpdateEventError] = React.useState(true);
  const [showDeleteEvent, setDeleteEvent] = React.useState(null);
  const [deletingEvent, setDeletingEvent] = React.useState(false);
  const [deleteEventError, setDeleteEventError] = React.useState("");
  const [showArchiveEvent, setArchiveEvent] = React.useState(null);
  const [archivingEvent, setArchivingEvent] = React.useState(false);
  const [archiveEventError, setArchiveEventError] = React.useState("");
  // const [gettingTeams, setGettingTeams] = React.useState(false);
  const [teams, setTeams] = React.useState([]);
  // const [gettingEventTypes, setGettingEventTypes] = React.useState(false);
  const [eventTypes, setEventTypes] = React.useState([]);
  const [redirectFlowUrl, setRedirectFlowUrl] = React.useState(null);
  const [payEventFee, setPayEventFee] = React.useState(null);
  const [gettingPaymentStatus, setGettingPaymentStatus] = React.useState(null);
  // const [gettingPaymentStatusError, setGettingPaymentStatusError] = React.useState(null);
  const [submittingPayment, setSubmittingPayment] = React.useState(false);
  const [submitPaymentError, setSubmitPaymentError] = React.useState(null);
  const [payForEventPassword, setPayForEventPassword] = React.useState(null);
  const [showEventsInfo, setShowEventsInfo] = React.useState(null);
  const [
    showUnconfirmedEventInfo,
    setShowUnconfirmedEventInfo,
  ] = React.useState(null);
  const [showUnpaidEventInfo, setShowUnpaidEventInfo] = React.useState(null);
  const [showPaidEventInfo, setShowPaidEventInfo] = React.useState(null);

  const getEvents = useCallback(async () => {
    if (
      !context.currentClub ||
      !context.currentClub.clubId ||
      !context.currentPlayer ||
      !context.currentPlayer.id
    ) {
      return false;
    }

    try {
      setGettingEvents(true);
      const data = await apiGetEvents(
        context.currentClub.clubId,
        context.currentPlayer.id
      );
      if (data && !data.reason) {
        setEvents(data);
      }
      setGettingEvents(false);
    } catch (e) {
      console.log(e);
      setGettingEvents(false);
    }
  }, [context.currentClub, context.currentPlayer]);

  const createEvent = async (payload) => {
    try {
      setCreatingEvent(true);
      await apiCreateEvent(
        context.currentClub.clubId,
        context.currentPlayer.id,
        {
          name: payload.name,
          eventDate: payload.eventDate || moment(),
          teamId: payload.teamId,
          eventTypeId: payload.eventTypeId,
        }
      );
      setCreatingEvent(false);
      toggleShowAddEvent(false);
      getEvents();
    } catch (e) {
      console.log(e);
      setCreatingEvent(false);
      setCreateEventError(e.message);
    }
  };

  const updateEvent = async (payload) => {
    try {
      setUpdatingEvent(true);
      await apiUpdateEvent(
        context.currentClub.clubId,
        payload.id,
        context.currentPlayer.id,
        payload
      );
      setEditEvent(null);
      setUpdatingEvent(false);
      getEvents();
    } catch (e) {
      setUpdatingEvent(false);
      setUpdateEventError(e.message);
    }
  };

  const deleteEvent = async () => {
    try {
      setDeletingEvent(true);
      await apiDeleteEvent(showDeleteEvent.id);
      setDeletingEvent(false);
      setDeleteEvent(null);
      getEvents();
    } catch (e) {
      setDeletingEvent(false);
      setDeleteEventError(e.message);
    }
  };

  const getTeams = useCallback(async () => {
    if (
      !context.currentClub ||
      !context.currentClub.clubId ||
      !context.currentPlayer ||
      !context.currentPlayer.id
    ) {
      return false;
    }

    try {
      // setGettingTeams(true);
      const data = await apiGetTeams(
        context.currentClub.clubId,
        context.currentPlayer.id
      );
      if (data && !data.reason) {
        setTeams(data);
      }
      // setGettingTeams(false);
    } catch (e) {
      console.log(e);
      // setGettingTeams(false);
    }
  }, [context.currentClub, context.currentPlayer]);

  const getEventTypes = useCallback(async () => {
    if (
      !context.currentClub ||
      !context.currentClub.clubId ||
      !context.currentPlayer ||
      !context.currentPlayer.id
    ) {
      return false;
    }

    try {
      // setGettingEventTypes(true);
      const data = await apiGetEventTypes(
        context.currentClub.clubId,
        context.currentPlayer.id
      );
      if (data && !data.reason) {
        setEventTypes(data);
      }
      // setGettingEventTypes(false);
    } catch (e) {
      console.log(e);
      // setGettingEventTypes(false);
    }
  }, [context.currentClub, context.currentPlayer]);

  const archiveEvent = async () => {
    try {
      setArchivingEvent(true);
      await apiArchiveEvent(showArchiveEvent.id);
      setArchivingEvent(null);
      setArchiveEvent(null);
      getEvents();
    } catch (e) {
      console.log(e);
      setArchivingEvent(false);
      setArchiveEventError(e.message);
    }
  };

  const payForEvent = async (event) => {
    const myPlayer = event.currentPlayerFee;
    if (myPlayer && myPlayer.currentFee > 0 && !myPlayer.isPaid) {
      setGettingPaymentStatus(true);

      try {
        const playerClubPaymentStatusResponse = await apiGetPlayerClubPaymentStatus(
          context.currentPlayer.id,
          context.currentClub.clubId
        );

        if (playerClubPaymentStatusResponse.success) {
          setPayEventFee(myPlayer);
          setGettingPaymentStatus(false);
        } else if (playerClubPaymentStatusResponse.redirectFlowUrl) {
          setRedirectFlowUrl(playerClubPaymentStatusResponse.redirectFlowUrl);
          setGettingPaymentStatus(false);
        } else if (playerClubPaymentStatusResponse.message) {
          // setGettingPaymentStatusError(playerClubPaymentStatusResponse.message);
          setGettingPaymentStatus(false);
        }
      } catch (e) {
        setGettingPaymentStatus(false);
      }
    }
  };

  const submitPayment = async () => {
    if (payForEventPassword.length > 0) {
      const myPlayerEvent = payEventFee;

      if (myPlayerEvent && !myPlayerEvent.isPaid) {
        setSubmittingPayment(true);

        try {
          const submitPaymmentResponse = await apiSubmitPayment({
            eventId: payEventFee.event.guid,
            targetPlayerId: context.currentPlayer.id,
            password: payForEventPassword,
          });

          if (submitPaymmentResponse.success) {
            setSubmittingPayment(false);
            setPayEventFee(null);
            getEvents();
            setPayForEventPassword("");
          } else if (
            submitPaymmentResponse.errors &&
            submitPaymmentResponse.errors.length > 0
          ) {
            setSubmitPaymentError(submitPaymmentResponse.errors[0].reason);
            setSubmittingPayment(false);
          } else if (submitPaymmentResponse.redirectFlowUrl) {
            setPayEventFee(null);
            setRedirectFlowUrl(submitPaymmentResponse.redirectFlowUrl);
            setSubmittingPayment(false);
          } else {
            setSubmitPaymentError("An error occurred completing payment");
            setSubmittingPayment(false);
          }
        } catch (e) {
          setSubmitPaymentError(e);
          setPayForEventPassword("");
          setSubmittingPayment(false);
        }
      }
    }
  };

  const unconfirmedEvents = events
    ? events.filter((event) => {
        return (
          (context.currentClub.isTreasurer || event.isCaptainForEventTeam) &&
          !event.isConfirmed
        );
      })
    : [];

  const unpaidEvents = events
    ? events.filter((event) => {
        if (context.currentClub.isTreasurer) {
          return (
            event.isConfirmed &&
            !event.hideForTreasurers &&
            event.currentPlayerFee &&
            !event.currentPlayerFee.isPaid &&
            event.currentPlayerFee.currentFee > 0
          );
        } else if (event.isCaptainForEventTeam) {
          return (
            event.isConfirmed &&
            !event.hideForCaptains &&
            event.currentPlayerFee &&
            !event.currentPlayerFee.isPaid &&
            event.currentPlayerFee.currentFee > 0
          );
        } else {
          return (
            event.isConfirmed &&
            event.currentPlayerFee &&
            !event.currentPlayerFee.isPaid &&
            event.currentPlayerFee.currentFee > 0
          );
        }
      })
    : [];

  const paidEvents = events
    ? events.filter((event) => {
        if (context.currentClub.isTreasurer) {
          return (
            !event.hideForTreasurers &&
            event.isConfirmed &&
            (!event.currentPlayerFee ||
              event.currentPlayerFee.isPaid ||
              event.currentPlayerFee.currentFee === 0)
          );
        } else if (event.isCaptainForEventTeam) {
          return (
            !event.hideForCaptains &&
            event.isConfirmed &&
            (!event.currentPlayerFee ||
              event.currentPlayerFee.isPaid ||
              event.currentPlayerFee.currentFee === 0)
          );
        } else {
          return (
            !event.hideForPlayer &&
            event.isConfirmed &&
            event.currentPlayerFee &&
            (event.currentPlayerFee.isPaid ||
              event.currentPlayerFee.currentFee === 0)
          );
        }
      })
    : [];

  const renderUnconfirmedEvents = () => {
    if (unconfirmedEvents && unconfirmedEvents.length > 0) {
      return (
        <div>
          <EventHeader
            title="UNCONFIRMED"
            onInfoClick={
              event_created[getPlayerType(context.currentClub)]
                ? () => setShowUnconfirmedEventInfo(true)
                : null
            }
            backgroundColor="#c13535"
          />
          <div>
            {unconfirmedEvents.map((unconfirmedEvent) => {
              let borderColor = "transparent";

              // A remove button is always shown for a unconfirmed event
              let actionButton = (
                <Button
                  onClick={() => setDeleteEvent(unconfirmedEvent)}
                  title="Remove"
                />
              );

              return (
                <Event
                  key={unconfirmedEvent.id}
                  to={`/events/${unconfirmedEvent.id}/edit`}
                  event={unconfirmedEvent}
                  borderColor={borderColor}
                  actionButton={actionButton}
                />
              );
            })}
          </div>
        </div>
      );
    }
  };

  const renderUnpaidEvents = () => {
    if (unpaidEvents && unpaidEvents.length > 0) {
      return (
        <div>
          <EventHeader
            title="UNPAID"
            onInfoClick={
              event_paid[getPlayerType(context.currentClub)]
                ? () => setShowUnpaidEventInfo(true)
                : null
            }
            backgroundColor="#c37b15"
          />
          <div>
            {unpaidEvents.map((unpaidEvent) => {
              let borderColor = "transparent";
              let actionButton = null;
              if (
                context.currentClub.isTreasurer ||
                unpaidEvent.isCaptainForEventTeam
              ) {
                if (unpaidEvent.haveAllPlayersPaid) {
                  // Confirmed event - team paid (incl. if logged in user paid)

                  borderColor = "#07843f";
                  actionButton = (
                    <Button
                      onClick={() => setArchiveEvent(unpaidEvent)}
                      title="Archive"
                    />
                  );
                } else {
                  // Confirmed event - team unpaid, logged in user unpaid (no fee due)

                  // orange
                  borderColor = "#c37c15";
                }

                if (!unpaidEvent.haveAnyPlayersPaid) {
                  actionButton = (
                    <Button
                      onClick={() => setDeleteEvent(unpaidEvent)}
                      title="Remove"
                    />
                  );
                } else if (
                  !unpaidEvent.haveAllPlayersPaid &&
                  unpaidEvent.currentPlayerFee !== null &&
                  unpaidEvent.currentPlayerFee.currentFee > 0 &&
                  !unpaidEvent.currentPlayerFee.isPaid
                ) {
                  actionButton = (
                    <Button
                      onClick={() => payForEvent(unpaidEvent)}
                      title="Pay"
                      submitting={gettingPaymentStatus}
                    />
                  );
                }
              } else {
                actionButton = (
                  <Button
                    onClick={() => payForEvent(unpaidEvent)}
                    title="Pay"
                    submitting={gettingPaymentStatus}
                  />
                );
              }
              return (
                <Event
                  key={unpaidEvent.id}
                  to={`/events/${unpaidEvent.id}`}
                  event={unpaidEvent}
                  actionButton={actionButton}
                  borderColor={borderColor}
                />
              );
            })}
          </div>
        </div>
      );
    }
  };

  const renderPaidEvents = () => {
    if (paidEvents && paidEvents.length > 0) {
      return (
        <div>
          <EventHeader
            title="PAID"
            onInfoClick={
              event_unpaid[getPlayerType(context.currentClub)]
                ? () => setShowPaidEventInfo(true)
                : null
            }
            backgroundColor="#07843e"
          />
          <div>
            {paidEvents.map((paidEvent) => {
              let borderColor = "transparent";
              let actionButton = null;

              if (
                context.currentClub.isTreasurer ||
                paidEvent.isCaptainForEventTeam
              ) {
                if (!paidEvent.haveAllPlayersPaid) {
                  // Confirmed event - team unpaid, logged in user paid (fee is set)

                  // if (event.player_type !== "player") {
                  // orange
                  borderColor = "#c37c15";
                  // }
                } else if (paidEvent.haveAllPlayersPaid) {
                  // Confirmed event - team paid (incl. if logged in user paid)

                  borderColor = "#07843f";
                  actionButton = (
                    <Button
                      onClick={() => setArchiveEvent(paidEvent)}
                      title="Archive"
                    />
                  );
                }
              } else {
                actionButton = (
                  <Button
                    onClick={() => setArchiveEvent(paidEvent)}
                    title="Archive"
                  />
                );
              }
              return (
                <Event
                  key={paidEvent.id}
                  to={`/events/${paidEvent.id}`}
                  event={paidEvent}
                  actionButton={actionButton}
                  borderColor={borderColor}
                />
              );
            })}
          </div>
        </div>
      );
    }
  };

  useEffect(() => {
    getEvents();
    getTeams();
    getEventTypes();
  }, [getEvents, getTeams, getEventTypes]);

  return (
    <PageWrapper
      history={history}
      showHeader={true}
      showSidebar={true}
      title="Events"
      onInfoClick={
        event_info[getPlayerType(context.currentClub)]
          ? () => setShowEventsInfo(true)
          : null
      }
    >
      <Section style={{ padding: 0 }}>
        <div className={classes.eventsButtons}>
          {(context.currentClub.isTreasurer ||
            context.currentClub.isCaptain) && (
            <Link to="/events/new">
              <Button title="Create" />
            </Link>
          )}
        </div>
        <div>
          {renderUnconfirmedEvents()}
          {renderUnpaidEvents()}
          {renderPaidEvents()}
          {!gettingEvents &&
            unconfirmedEvents.length === 0 &&
            unpaidEvents.length === 0 &&
            paidEvents.length === 0 && (
              <div style={{ textAlign: "center", padding: "20px 0" }}>
                {no_events[getPlayerType(context.currentClub)]}
              </div>
            )}
        </div>
      </Section>

      {showAddEvent && (
        <DialogModal
          submitText="Add"
          onSubmit={() => createEvent(newEvent)}
          onClose={() => toggleShowAddEvent(false)}
          error
        >
          <AddEventForm
            payload={newEvent}
            storePayload={(payload) => storeNewEvent(payload)}
            errorMessage={createEventError}
            submitting={creatingEvent}
            teams={teams}
            eventTypes={eventTypes}
          />
        </DialogModal>
      )}

      {editEvent && (
        <DialogModal
          submitText="Update"
          onSubmit={() => updateEvent(editEvent)}
          onClose={() => setEditEvent(null)}
          error
        >
          <EditEventForm
            payload={editEvent}
            storePayload={(payload) => setEditEvent(payload)}
            errorMessage={updateEventError}
            submitting={updatingEvent}
            teams={teams}
            eventTypes={eventTypes}
          />
        </DialogModal>
      )}

      {showDeleteEvent && (
        <YesNoDialogModal
          title="Are you sure you want to delete event?"
          onClose={() => setDeleteEvent(false)}
          onSubmit={() => deleteEvent()}
          submitting={deletingEvent}
          error={deleteEventError}
        />
      )}

      {showArchiveEvent && (
        <YesNoDialogModal
          title="Are you sure you want to archive event?"
          onClose={() => setArchiveEvent(false)}
          onSubmit={() => archiveEvent()}
          submitting={archivingEvent}
          error={archiveEventError}
        />
      )}

      {payEventFee && (
        <DialogModal
          title="Make payment"
          onClose={() => setPayEventFee(null)}
          onSubmit={() => submitPayment()}
          submitText="Pay"
          submitting={submittingPayment}
        >
          <PayForEventForm
            payload={{ ...payEventFee, password: payForEventPassword }}
            storePayload={(value) => setPayForEventPassword(value)}
            errorMessage={submitPaymentError}
          />
        </DialogModal>
      )}

      {redirectFlowUrl && (
        <DialogModal
          title="Setup your direct debit"
          onClose={() => setRedirectFlowUrl(null)}
        >
          <div>
            You haven't yet set up direct debits for Slate. Click{" "}
            <a href={redirectFlowUrl}>here</a> to do this now.
          </div>
        </DialogModal>
      )}

      {showEventsInfo && (
        <DialogModal
          title="Your events"
          onClose={() => setShowEventsInfo(null)}
          cancelText="Close"
        >
          <div>{event_info[getPlayerType(context.currentClub)]}</div>
        </DialogModal>
      )}

      {showUnconfirmedEventInfo && (
        <DialogModal
          title="Unconfirmed events"
          onClose={() => setShowUnconfirmedEventInfo(null)}
          cancelText="Close"
        >
          <div>{event_created[getPlayerType(context.currentClub)]}</div>
        </DialogModal>
      )}

      {showUnpaidEventInfo && (
        <DialogModal
          title="Unpaid events"
          onClose={() => setShowUnpaidEventInfo(null)}
          cancelText="Close"
        >
          <div>{event_unpaid[getPlayerType(context.currentClub)]}</div>
        </DialogModal>
      )}

      {showPaidEventInfo && (
        <DialogModal
          title="Unconfirmed events"
          onClose={() => setShowPaidEventInfo(null)}
          cancelText="Close"
        >
          <div>{event_paid[getPlayerType(context.currentClub)]}</div>
        </DialogModal>
      )}
    </PageWrapper>
  );
}

const EventHeader = withStyles(styles)(
  ({ title, classes, onInfoClick, backgroundColor }) => {
    return (
      <div
        className={classes.eventHeader}
        style={{ backgroundColor: backgroundColor || "transparent" }}
      >
        {title}
        {onInfoClick && (
          <div className={classes.infoClick} onClick={() => onInfoClick()}>
            <Info />
          </div>
        )}
      </div>
    );
  }
);

export default withStyles(styles)(withState(EventsPage));
