import React from "react";
import { withStyles } from "@material-ui/core/styles";

import Label from "../label/Label";

const styles = (theme) => ({
  singleLineTextInput: {
    marginBottom: "10px",
  },
  input: {
    width: "100%",
    border: "1px solid #D7D7D7",
    borderRadius: "3px",
    outline: "none",
    paddingLeft: "10px",
    paddingTop: "10px",
    paddingBottom: "10px",
    fontSize: "18px",
  },
  invalidInput: {
    border: "1px solid red",
  },
});

function MultiLineTextInput({ classes, style, value, label, type, showInvalidInput, onChange, placeholder, required }) {
  let inputClassName = classes.input;

  if (showInvalidInput) {
    inputClassName += ` ${classes.invalidInput}`;
  }

  return (
    <div className={classes.singleLineTextInput}>
    {label && <Label title={label} required={required} />}
      <textarea className={inputClassName} style={style || {}} value={value} type={type || "text"} onChange={(event) => onChange(event.target.value)} placeholder={placeholder} />
    </div>
  );
}

export default withStyles(styles)(MultiLineTextInput);
