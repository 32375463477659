import React from "react";
import { withStyles } from "@material-ui/core/styles";
import CircularProgress from "@material-ui/core/CircularProgress";

const styles = (theme) => ({
  button: {
    backgroundColor: "#3CC87B",
    borderRadius: "3px",
    padding: "10px 15px",
    color: "#fff",
    cursor: "pointer",
    display: "inline-block",
    minWidth: 100,
    textAlign: "center",
  },
  spinner: {
    color: "#fff",
  },
});

function Button({ classes, style, title, onClick, submitting, disabled }) {
  return (
    <div className={classes.button} style={style || { opacity: disabled ? 0.4 : 1 }} onClick={() => !submitting && !disabled && onClick && onClick()}>
      {!submitting && title}
      {submitting && <CircularProgress className={classes.spinner} size={14} />}
    </div>
  );
}

export default withStyles(styles)(Button);
