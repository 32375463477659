import React from "react";
import { withStyles } from "@material-ui/core/styles";

import { withState } from "../../../services/state";

import PageWrapper from "../../components/pageWrapper/PageWrapper";
import AuthenticationFlow from "../../components/authenticationFlow/AuthenticationFlow";
import Button from "../../components/button/Button";

const styles = (theme) => ({
  noClubsHeading: {
    fontSize: 25,
    marginBottom: 10,
  },
  noClubsDescription: {
    fontSize: 16,
    marginBottom: 20,
  },
});

const NoClubsPage = ({ history, match, dispatch, classes }) => {
  const logout = () => {
    dispatch({
      type: "clearToken",
    });
    dispatch({
      type: "clearSessionVariables",
    });
    history.push("/");
  };

  return (
    <PageWrapper history={history} showHeader={true}>
      <AuthenticationFlow
        currentStep={0}
        steps={[
          <div>
            <div className={classes.noClubsHeading}>No clubs available</div>
            <div className={classes.noClubsDescription}>
              You do not belong to any clubs in this organisation. Please try
              contacting your treasurer so they can invite you to a club.
            </div>
            <Button
              title="Logout"
              style={{
                backgroundColor: "rgb(243, 243, 243)",
                color: "#222",
                marginBottom: 10,
              }}
              onClick={() => logout()}
            />
          </div>,
        ]}
      />
    </PageWrapper>
  );
};

export default withStyles(styles)(withState(NoClubsPage));
