import React, { useEffect, useCallback } from "react";
import { withStyles } from "@material-ui/core/styles";
import moment from "moment";

import { withState } from "../../../services/state";

import { getEvent as apiGetEvent } from "../../../services/api";

import PageWrapper from "../../components/pageWrapper/PageWrapper";
import Section from "../../components/section/Section";
import Label from "../../components/label/Label";

import EventPlayersTable from "../../tables/eventPlayersTable/EventPlayersTable";

const styles = (theme) => ({
  root: {
    width: "100%",
  },
  detail: {
    marginBottom: 20,
  },
});

function EventPage({ history, match, classes, dispatch, context }) {
  const [gettingEvent, setGettingEvent] = React.useState(true);
  const [event, setEvent] = React.useState({});

  const getEvent = useCallback(async () => {
    try {
      setGettingEvent(true);
      const data = await apiGetEvent(match.params.eventId);
      if (data && !data.reason) {
        setEvent(data);
      }
      setGettingEvent(false);
    } catch (e) {
      console.log(e);
      setGettingEvent(false);
    }
  }, [match.params.eventId]);

  useEffect(() => {
    getEvent();
  }, [getEvent]);

  return (
    <PageWrapper
      history={history}
      showHeader={true}
      showSidebar={true}
      title="Event"
      showLoading={gettingEvent}
    >
      <Section>
        {event && event.type && event.team && (
          <div>
            <div className={classes.detail}>
              <Label title="NAME" />
              <div>{event.name}</div>
            </div>
            <div className={classes.detail}>
              <Label title="EVENT DATE" />
              <div>{moment.utc(event.eventDate).format("Do MMMM YYYY")}</div>
            </div>
            <div className={classes.detail}>
              <Label title="EVENT TYPE" />
              <div>{event.type.name}</div>
            </div>
            <div className={classes.detail}>
              <Label title="TEAM" />
              <div>{event.team.name}</div>
            </div>
          </div>
        )}
      </Section>

      <Section>
        <EventPlayersTable
          players={event.players}
          isTreasurerOrCaptain={
            context.currentClub.isTreasurer ||
            (event && event.isCaptainForEventTeam)
          }
          myPlayer={context.currentPlayer}
        />
      </Section>
    </PageWrapper>
  );
}

export default withStyles(styles)(withState(EventPage));
