import React from "react";
import { Link } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";

import FormHeader from "../../components/formHeader/FormHeader";
import FormDescription from "../../components/formDescription/FormDescription";
import SingleLineTextInput from "../../components/singleLineTextInput/SingleLineTextInput";
import Checkbox from "../../components/checkbox/Checkbox";
import Button from "../../components/button/Button";
import ErrorMessage from "../../components/errorMessage/ErrorMessage";

const isInvalidEmail = (email) => !email;
const isInvalidConfirmEmail = (email, confirmEmail) => email !== confirmEmail;
const isInvalidPassword = (password) => !password;
const isInvalidConfirmPassword = (password, confirmPassword) => password !== confirmPassword;

const styles = (theme) => ({
  section: {
    width: "100%",
    backgroundColor: "#fff",
  },
  twoFields: {
    display: "flex",
    width: "100%",
    [theme.breakpoints.down("sm")]: {
      display: "block"
    },
  },
  authLinks: {
    marginTop: 20,
  },
  authLink: {
    margin: "10px 0",
  },
});

function RegisterForm({ classes, onSubmit, registerError, registering, submitText }) {
  const [submitAttempted, setSubmitAttempted] = React.useState(false);
  const [payload, storePayload] = React.useState({});

  console.log(submitText);
  return (
    <div className={classes.section}>
      <div className={classes.setupInformation}>
        <FormHeader title="Account details" />
        <FormDescription>
          <div>Enter your email address and password</div>
        </FormDescription>
      </div>
      <div className={classes.twoFields}>
        <SingleLineTextInput
          label="EMAIL ADDRESS*"
          onChange={(value) => storePayload({ ...payload, emailAddress: value })}
          showInvalidInput={submitAttempted && isInvalidEmail(payload.emailAddress)}
          style={{ width: "100%", marginRight: 10 }}
          type="email"
        />
        <SingleLineTextInput
          label="CONFIRM EMAIL ADDRESS*"
          onChange={(value) => storePayload({ ...payload, confirmEmailAddress: value })}
          showInvalidInput={submitAttempted && isInvalidConfirmEmail(payload.emailAddress, payload.confirmEmailAddress)}
          style={{ width: "100%" }}
          type="email"
        />
      </div>
      <div className={classes.twoFields}>
        <SingleLineTextInput
          label="PASSWORD* (min 8 characters, 1 upper case, 1 number)"
          onChange={(value) => storePayload({ ...payload, password: value })}
          showInvalidInput={submitAttempted && isInvalidPassword(payload.password)}
          style={{ width: "100%", marginRight: 10 }}
          type="password"
        />
        <SingleLineTextInput
          label="CONFIRM PASSWORD*"
          onChange={(value) => storePayload({ ...payload, confirmPassword: value })}
          showInvalidInput={submitAttempted && isInvalidConfirmPassword(payload.password, payload.confirmPassword)}
          style={{ width: "100%" }}
          type="password"
        />
      </div>
      <div>
        <Checkbox
          label="Sign me up to hear about the latest Slate news"
          value={payload.signUpToNewsletter}
          onClick={() => storePayload({ ...payload, signUpToNewsletter: !payload.signUpToNewsletter })}
        />
        <Checkbox
          label={
            <div>
              I agree to the Slate <a href="https://slateapp.co.uk/terms-conditions/">terms</a> and <a href="https://slateapp.co.uk/privacy-policy/">privacy policy</a>
            </div>
          }
          value={payload.agreeToTerms}
          onClick={() => storePayload({ ...payload, agreeToTerms: !payload.agreeToTerms })}
        />
      </div>
      <ErrorMessage message={registerError} />
      <Button
        title={submitText || "Next"}
        onClick={() => {
          setSubmitAttempted(true);
          onSubmit(payload);
        }}
        submitting={registering}
      />

      <div className={classes.authLinks}>
        <Link to="/login">
          <div className={classes.authLink}>Login to your account</div>
        </Link>
      </div>
    </div>
  );
}

export default withStyles(styles)(RegisterForm);
