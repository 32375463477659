import React from "react";
import { withStyles } from "@material-ui/core/styles";
import Add from "@material-ui/icons/Add";
import Close from "@material-ui/icons/Close";
import { CircularProgress } from "@material-ui/core";

const styles = (theme) => ({
  section: {
    width: "100%",
    backgroundColor: "#fff",
  },
  playersList: {
    marginBottom: 20,
  },
  selectPlayer: {
    padding: "10px",
    cursor: "pointer",
    "&:not(:last-child)": {
      borderBottom: "1px solid #ececec",
    },
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  modalButtons: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    marginTop: 20,
  },
  addPlayer: {
    backgroundColor: "#3CC87B",
    width: 30,
    height: 30,
    color: "#fff",
    borderRadius: 15,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  removePlayer: {
    backgroundColor: "#ff5757",
    width: 30,
    height: 30,
    color: "#fff",
    borderRadius: 15,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  addPlayerSpinner: {
    color: "#fff",
    height: "15px !important",
    width: "15px !important",
  },
});

function ToggleEventPlayersForm({ classes, members, players, onAddPlayer, addingPlayer, onRemovePlayer, deletingPlayer, filterPaidPlayers }) {
  //   const [showCreatePlayer, setShowCreatePlayer] = React.useState(false);
  //   const [addMember, setAddMember] = React.useState({});

  const renderContent = () => {
    return (
      <div className={classes.playersList}>
        {members
          .filter((member) => {
            if (filterPaidPlayers) {
              const eventPlayer = players.find((eventPlayer) => eventPlayer.player.id === member.playerId);
              return !eventPlayer || (eventPlayer && !eventPlayer.isPaid);
            }
            return true;
          })
          .map((member) => {
            const eventPlayer = players.find((eventPlayer) => eventPlayer.player.id === member.playerId);
            return (
              <div key={`player-${member.playerId}`} className={classes.selectPlayer}>
                <div>
                  {member.firstName} {member.lastName}
                </div>
                <div>
                  {!eventPlayer && (
                    <div className={classes.addPlayer} onClick={() => onAddPlayer(member)}>
                      {addingPlayer === member.playerId ? <CircularProgress className={classes.addPlayerSpinner} /> : <Add />}
                    </div>
                  )}
                  {eventPlayer && (
                    <div className={classes.removePlayer} onClick={() => onRemovePlayer(eventPlayer)}>
                      {deletingPlayer === member.playerId ? <CircularProgress className={classes.addPlayerSpinner} /> : <Close />}
                    </div>
                  )}
                </div>
              </div>
            );
          })}
      </div>
    );
  };

  return (
    <div className={classes.section}>
      <div>{renderContent()}</div>
    </div>
  );
}

export default withStyles(styles)(ToggleEventPlayersForm);
