import React from "react";
import { withStyles } from "@material-ui/core/styles";
import { Link } from "react-router-dom";

import { withState } from "../../../services/state";

import FormHeader from "../../components/formHeader/FormHeader";
import SuccessMessage from "../../components/successMessage/SuccessMessage";
import ErrorMessage from "../../components/errorMessage/ErrorMessage";

const styles = (theme) => ({
  section: {
    width: "100%",
    backgroundColor: "#fff",
  },
  twoFields: {
    display: "flex",
    width: "100%",
  },
  authLinks: {
    marginTop: 20,
  },
  authLink: {
    margin: "10px 0",
  },
});

function ResetPasswordForm({ classes, message, error, context }) {
  return (
    <div className={classes.section}>
      <div className={classes.setupInformation}>
        <FormHeader title="Verify account" />
      </div>
      <ErrorMessage message={error} />
      <SuccessMessage message={message} />
      {!context.profile.id && (
        <div className={classes.authLinks}>
          <Link to="/login">
            <div className={classes.authLink}>Login to your account</div>
          </Link>
        </div>
      )}
    </div>
  );
}

export default withStyles(styles)(withState(ResetPasswordForm));
