import React, { useEffect, useCallback } from "react";
import { withStyles } from "@material-ui/core/styles";

import { withState } from "../../../services/state";
import { getEvents as apiGetEvents } from "../../../services/api";

import PageWrapper from "../../components/pageWrapper/PageWrapper";
import Section from "../../components/section/Section";
import Event from "../../components/event/Event";
import DialogModal from "../../components/dialogModal/DialogModal";

const styles = (theme) => ({
  root: {
    width: "100%",
  },
});

const getPlayerType = (currentClub) => {
  if (currentClub.isTreasurer) return "treasurer";
  if (currentClub.isCaptain) return "captain";
  return "player";
};

const event_info = {
  player:
    'This is a list of all team events you\'ve been added to and need to pay for. To pay for an unpaid event, simply slide left on the event and click the "PAY" button, or tap an event to view full team details.',
  treasurer:
    'This is a list of all team events that have been set-up for your Club. You can add new events at any time by clicking "CREATE" or tap an event to view full team details. If you are also a player in one of your teams, you can pay for an unpaid event by sliding left on the event and click the "PAY" button.',
  captain:
    'This is a list of all team events you\'ve been added to and need to pay for. To pay for an unpaid event, simply slide left on the event and click the "PAY" button, or tap an event to view full team details. If you have been selected as a Manager for your team, you can also see who else on the teamsheet has paid, and have the option to pay on behalf of another player. You can also add new events by clicking "CREATE".',
};

function ArchivePage({ history, classes, dispatch, context }) {
  const [gettingEvents, setGettingEvents] = React.useState(false);
  const [events, setEvents] = React.useState([]);
  const [showArchieveInfo, setShowArchiveInfo] = React.useState(false);

  const getEvents = useCallback(async () => {
    if (
      !context.currentClub ||
      !context.currentClub.clubId ||
      !context.currentPlayer ||
      !context.currentPlayer.id
    ) {
      return false;
    }

    try {
      setGettingEvents(true);
      const data = await apiGetEvents(
        context.currentClub.clubId,
        context.currentPlayer.id
      );
      if (data && !data.reason) {
        setEvents(data);
      }
      setGettingEvents(false);
    } catch (e) {
      console.log(e);
      setGettingEvents(false);
    }
  }, [context.currentClub, context.currentPlayer]);

  const renderEvents = () => {
    const archivedEvents = events
      ? events.filter((event) => {
          if (context.currentClub.isTreasurer) {
            // return event.hideForTreasurers && event.isConfirmed && event.currentPlayerFee && (event.currentPlayerFee.isPaid || event.currentPlayerFee.currentFee === 0);
            return event.hideForTreasurers && event.isConfirmed;
          } else if (event.isCaptainForEventTeam) {
            // return event.hideForCaptains && event.isConfirmed && event.currentPlayerFee && (event.currentPlayerFee.isPaid || event.currentPlayerFee.currentFee === 0);
            return event.hideForCaptains && event.isConfirmed;
          } else {
            return event.hideForPlayer;
          }
        })
      : [];

    if (archivedEvents && archivedEvents.length > 0) {
      return (
        <div>
          {archivedEvents.map((archivedEvent) => {
            return (
              <Event
                key={`event-${archivedEvent.id}`}
                to={`/archive/${archivedEvent.id}`}
                event={archivedEvent}
              />
            );
          })}
        </div>
      );
    }
  };

  useEffect(() => {
    getEvents();
  }, [getEvents]);

  return (
    <PageWrapper
      history={history}
      showHeader={true}
      showSidebar={true}
      title="Archive"
      onInfoClick={
        event_info[getPlayerType(context.currentClub)]
          ? () => setShowArchiveInfo(true)
          : null
      }
      showLoading={gettingEvents}
    >
      <Section>
        <div>{renderEvents()}</div>
      </Section>

      {showArchieveInfo && (
        <DialogModal
          title="Your events"
          onClose={() => setShowArchiveInfo(null)}
          cancelText="Close"
        >
          <div>{event_info[getPlayerType(context.currentClub)]}</div>
        </DialogModal>
      )}
    </PageWrapper>
  );
}

export default withStyles(styles)(withState(ArchivePage));
